import React, { useState,useEffect } from 'react';
import './calendar.css';
import moment from 'moment';
import Calendar from 'react-calendar';
import { momentLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';

const localizer = momentLocalizer(moment);

export default function CustomToolbar(props){
  const [showCalendar, setShowCalendar] = useState(false);
  const [currentView, setCurrentView] = useState('month');
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.body.setAttribute('dir', i18n.language === 'ar' ? 'rtl' : 'ltr');
    moment.locale(i18n.language);
  }, [i18n.language]);
  const goToBack = () => {
    const newDate = moment(props.date).subtract(1, 'months');
    props.onNavigate('DATE', newDate.toDate());
  };
  const goToNext = () => {
    props.onNavigate('NEXT');
  };
  const goToCurrent = () => {
    props.onNavigate('TODAY');
  };
  const goToDate = (date) => {
    props.onNavigate('DATE', date);
  };
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };
  const handleViewChange = (view) => {
    setCurrentView(view);
  };
  const date = moment(props.date);
  let label;
  if (currentView === 'month') {
    label = date.format('MMMM YYYY');
  } else if (currentView === 'day') {
    label = date.format('dddd, MMMM D, YYYY'); 
  }

  return (
    <>
      <div className="rbc-toolbar">
        <div>
          <h3 className="rbc-toolbar-label alltext-side-color">
            {label}
              <i onClick={toggleCalendar} className="ps-1 fa-solid fa-calendar-days fa-xs" style={{ color: "#12374d" }}></i>
          </h3>
          <div>
            {showCalendar && (
              <Calendar localizer={localizer} onChange={goToDate} value={date} onClickDay={goToDate} showNavigation={true}showFixedNumberOfWeeks={true}/>
            )}
          </div>
        </div>
        <div>
          <select name="view" id="view" className='form-select bg-transparent' onChange={(e) => {
            const view = e.target.value;
            if (view === 'day') {
              handleViewChange(view);
            } else if (view === 'back') {
              goToBack();
            } else if (view === 'next') {
              goToNext();
            } else if (view === 'today') {
              goToCurrent();
            }
          }}>
            <option value="all" className='d-none'>{t("all")}</option>
            <option value="today">{t("today")}</option>
            <option value="day">{t("day")}</option>
            <option value="back">{t("back")}</option>
            <option value="next">{t("next")}</option>
          </select>
        </div>
      </div>
    </>
  );
};
