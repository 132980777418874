// CustomHeader.js
import React from 'react';
import moment from 'moment';
import './calendar.css';

export default  function CustomHeader({  date,onNavigate }) {
  const dayLabel = moment(date).format('ddd'); 
  const dayLabel2 = moment(date).format('DD');

  return (
    <>
    <div>
        {dayLabel}
    </div>
    <div>
        {dayLabel2}
    </div>
    </>
  );
};
