import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function LoginVerify() {
    const navigate = useNavigate();
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyUp = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return <>
  <div className=" container-fluid p-0 m-0 ">
    <div className="row p-0 m-0">
      <div className="col-md-6 p-0 m-0">
        <img src="/Images/reg-image4.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg' alt="" />
      </div>
      <div className="col-md-6">
        <div className='w-100 text-end'>
          <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
        </div>
        <div className='row authenticationBody'>
          <div className="col-md-10 offset-md-1 ">
            <form className="row mt-4">  
              <div className="col-md-10 offset-md-1 ps-0">
    
                <div className="verify-container position-relative">
                  <h1>Verify your password</h1>
                  <p>Please Enter The four digit code sent to</p>
                  <p className=' text-danger'>Your Email</p>
                    <form className='mb-4 '>
                      <div className="row text-center">
                          {[...Array(4)].map((_, index) => (
                          <div className="col-md-3">
                            <input
                              key={index}
                              type="number"
                              className='border-bottom-custom text-center'
                              maxLength="1"
                              ref={(el) => (inputRefs.current[index] = el)}
                              onChange={(e) => handleInputChange(e, index)}
                              onKeyUp={(e) => handleKeyUp(e, index)}
                              
                            />
                          </div>
                        ))}
                        
                      </div>
                    {/* Your verification code input fields here */}
                    
                    </form>
                    <div className='line'></div>
                </div>

              </div>
              
              <div className="row p-0 mt-4">
                <div className="col-md-10 offset-md-1  pe-0">
                  <button type="submit"  className="btn btn-warning w-100 p-3 m-0">Verify</button>
                </div>
              </div>
            </form>


            
          </div>
        </div>
      </div>
    </div>
  </div>
</>
}
