import React, { useState, useEffect, useMemo  } from 'react'
import { usePost } from '../../usePost';
import { usePrice } from '../../../PriceContext';
import { useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWallet } from '../../../WalletContext';

export default function Credit({course}) {
  const user = JSON.parse(localStorage.getItem("user") || '{}');
  const userId = user.id || null;
  const { wallet } = useWallet();
  const [checkoutData, setCheckoutData] = useState(null);
  const { makeRequest, loading, error, data } = usePost("https://back.ig-way.com/api/checkout");
  const { price, selectedItems, withAssignments, type, newSelectedItemsIds, updateCheckout } = usePrice();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Memoize checkout data to prevent unnecessary re-renders
  const memoizedCheckoutData = useMemo(() => {
    if (!userId || price === undefined) return null;

    let newCheckoutData = {
      type: type,
      payment_method: 2,
      user_id: userId,
      with_assignments: withAssignments,
      value: price,
    };

    if (type === 1) { // Course
      newCheckoutData = {
        ...newCheckoutData,
        course_id: selectedItems.course?.id, // Select course ID
      };
    } else if (type === 2) { // Topics
      newCheckoutData = {
        ...newCheckoutData,
        topics: newSelectedItemsIds,
      };
    } else if (type === 3) { // Subcourses
      newCheckoutData = {
        ...newCheckoutData,
        subcourses: newSelectedItemsIds,
      };
    } else if (type === 4) { // Lessons
      newCheckoutData = {
        ...newCheckoutData,
        lessons: newSelectedItemsIds,
      };
    }

    return newCheckoutData;
  }, [type, selectedItems, withAssignments, price, userId, newSelectedItemsIds]);

  useEffect(() => {
    if (memoizedCheckoutData) {
      setCheckoutData(memoizedCheckoutData);
    }
  }, [memoizedCheckoutData]);


    const handleCheckout = async (e) => {
      e.preventDefault();
      if (checkoutData) {
        
        await makeRequest(checkoutData);
        navigate(`/layout/coursedetails2/${course.id}`);
      } else {
        console.error("Checkout data is null or undefined.");
      }
    };
  
    const handleShowModal = () => {
      if (price <= wallet) {
        handleCheckout();
      } else {
        setShowModal(true);
      }
    };
    const handleCloseModal = () => {
      setShowModal(false);
    };
    
    const rechargeWallet = () => {
      navigate("/layout/wallet");
    };

  return (
    <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0">
        <p className='text-center fw-bold mt-4'>{t("willPay")} {price}</p>
        {loading ? (
          <button type="submit" className={`w-100 btn btn-warning mt-2 fw-bold py-2 alltext-side-color shadow`} disabled>
            <i className="fa-solid fa-spinner fa-pulse"></i>
        </button>
        ) : (
          <button type="submit" className={`w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`} onClick={handleShowModal}>
            {t("recharge")}
        </button>
        )}
        {error && <p className='text-danger text-center mt-4'>{error}</p>}
        {errorMessage && <p className='text-danger text-center mt-3 me-4'>{errorMessage}</p>}

        {price > wallet && showModal && (
          <div className="modal fade show d-block igWallet-modal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-4">
                <div className="modal-header justify-content-evenly">
                  <h5 className="modal-title ms-5 me-5" id="exampleModalLongTitle">{t("notCompletedPayment")}</h5>
                  <button type="button" className="close btn btn-main-color text-white fw-bold px-1 py-0 rounded ms-2" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true" className='p-1'>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-column align-items-center">
                    <img src="/Images/igwallet-girl.png" alt="No Money" />
                    <p className="alltext-black-color text-center">{t("notCompletedText")}</p>
                  </div>
                  <div className="modalBtns row">
                    <div className="col-md-6 pe-2">
                      <button className="btn btn-warning w-100 p-2 alltext-side-color fw-bold" onClick={rechargeWallet}>{t("charge")}</button>
                    </div>
                    <div className="col-md-6 ps-0">
                      <button className="btn btn-outline-secondary w-100 p-2 alltext-side-color fw-bold" onClick={handleCloseModal}>{t("anotherPayment")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
    )
}
