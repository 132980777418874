// src/Components/LanguageSwitcher.js
import React,{ useCallback }from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const LanguageSwitcher = ({onClick}) => {

  const { i18n } = useTranslation();
  const changeLanguage = useCallback(async (lang) => {
    try {
      await i18n.changeLanguage(lang);
      const token = localStorage.getItem("token");
      await axios.post(`https://back.ig-way.com/api/user/change-language`, { lang }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      console.log('Language changed successfully');
      i18n.reloadResources(); // Ensure translations are reloaded
    } catch (error) {
      console.error('Failed to change language:', error);
    }
  }, [i18n]);

  return (
    <div className="language-switcher">
      <button className='btn btn-warning mx-2' onClick={() =>
      {
        changeLanguage('en');
        onClick();
      }
      }>English</button>
      <button className='btn btn-warning' onClick={() =>
      {
        changeLanguage('ar');
        onClick();
      }
      }>عربى</button>
    </div>
  );
};

export default LanguageSwitcher;
