import React from 'react'
import { Circles } from "react-loader-spinner";

export default function Loading() {
  return (
    <div className="d-flex justify-content-center align-items-center spiner">
    <Circles
    height="80"
    width="80"
    color="#FFBC15"
    ariaLabel="circles-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    />
  </div>
  )
}
