import axios from "axios";
import React, { useEffect, useState } from "react";
import CourseEnrolledItem from "../CourseItem/CourseEnrolledItem";
import $ from "jquery";
import { useTranslation } from 'react-i18next';
import { Circles } from "react-loader-spinner";



export default function EnrolledCourses() {
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [selectedBoards, setSelectedBoards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const handleCheckboxChange = (setter, value) => {
    setter((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };
  function handleSlideDown(event) {
    $(event.currentTarget).parent().find(".drop-list").slideToggle();
    $(event.currentTarget).find(".arrow-icon").toggleClass("up");
  }
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        "https://back.ig-way.com/api/user/enrolled-courses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setEnrolledCourses([...data.data]);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleFilter = async (e) => {

    e.preventDefault();
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const levelsMap = { "Level 1": 1, "Level 2": 2, "Level 3": 3, "Level 4": 4 };
    const boardsMap = { "Board 1": 1, "Board 2": 2 };

    const selectedLevel = selectedLevels.length > 0 ? levelsMap[selectedLevels[0]] : null;
    const selectedBoard = selectedBoards.length > 0 ? boardsMap[selectedBoards[0]] : null;
    try {
      let {data} = await axios.get(`https://back.ig-way.com/api/courses?board=${selectedBoard}&level=${selectedLevel}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setEnrolledCourses([...data.data]);
      setIsLoading(false);
    } catch (error) {
      
    }
  };
  return (
    <>
      {isLoading === true ?
        <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        :
        <div className="row mt-3 enrolled-course smallSizes">
          <div className="col-md-3 filterSmallSize">
            <div className=" shadow p-3 rounded-2 bg-white">
              <div className="d-flex justify-content-between ">
                <div className="d-flex align-items-center">
                  <img src="/Images/filter.png" className="   mx-2" alt="" />
                  <h5>{t("filter")}</h5>
                </div>
                <button
                  className="btn btn-link text-decoration-none text-warning"
                  onClick={() => {
                    setSelectedLevels([]);
                    setSelectedBoards([]);
                  }}
                >
                  {t("reset")}
                </button>
              </div>
              <hr className="w-75 mx-auto " />
              <form onSubmit={handleFilter}>
              <div className="mb-3">
                <label
                  onClick={handleSlideDown}
                  className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                >
                  <p className="p-0 m-0">Levels</p>
                  <img
                    src="/Images/arrow-down.png"
                    className="search arrow-icon"
                    alt=""
                  />
                </label>

                <div className="drop-list hidden px-3 bg-light py-2 rounded-2">
                  {["Level 1", "Level 2", "Level 3", "Level 4"].map((level, index) => (
                    <div key={index} className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                      <label className="form-check-label">{level}</label>
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        value={level}
                        onChange={() => handleCheckboxChange(setSelectedLevels, level)}
                        checked={selectedLevels.includes(level)}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="mb-3">
                <label
                  onClick={handleSlideDown}
                  className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                >
                  <p className="p-0 m-0">Board</p>
                  <img
                    src="/Images/arrow-down.png"
                    className="search arrow-icon"
                    alt=""
                  />
                </label>
                <div className="drop-list hidden px-3 bg-light py-2 rounded-2">
                  {["Board 1", "Board 2"].map((board, index) => (
                    <div key={index} className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                      <label className="form-check-label">{board}</label>
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        value={board}
                        onChange={() => handleCheckboxChange(setSelectedBoards, board)}
                        checked={selectedBoards.includes(board)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 text-center">
                <button type="submit" className="btn border border-2 border-black w-50 mx-auto">
                  {t("apply")}
                </button>
              </div>
          </form>
            </div>
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between ">
                <div className="d-flex align-items-center bg-white search-div shadow rounded-2 py-1 px-2 ">
                  <img src="/Images/search.png" className="search" alt="" />
                  <input
                    type="text"
                    className="form-control px-2 border-0"
                    placeholder={t("search")}
                  />
                </div>
                <div className="d-flex align-items-center me-4">
                  <img src="/Images/sort.png " className="mx-2" alt="" />
                  <p className="m-0 p-0">{t("sort")}</p>
                </div>
              </div>
              <div className="row"></div>
              {enrolledCourses.length === 0 ? (
                <div className="col-md-12 mt-2">
                  <div className="text-center">
                    <img src="/Images/Student stress-pana1.png" alt="" className="w-50" />
                    <h2 className="opacity-50">
                      {t("notEnrolled")}
                    </h2>
                    <button className="btn btn-warning fw-bold mt-3 w-50 py-3 large-font">
                      {t("getStart")}
                    </button>
                  </div>
                </div>
              ) : (
                enrolledCourses.map((course , index) => (
                  <CourseEnrolledItem course={course} key={index} />
                ))
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
}
