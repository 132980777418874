import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CourseHomeItem from "../../Courses/CourseItem/CourseHomeItem";



export default function LandingPageHome() {
    const { t, i18n } = useTranslation();
    const [homeCourses, setHomeCourses] = useState([]);
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      };

    useEffect(() => {
        getCourses();
    }, []);
    const getCourses = async () => {
        try {
            let { data } = await axios.get("https://back.ig-way.com/api/courses");
            setHomeCourses(data.data);
            console.log(data);
          } catch (err) {
            console.log(err);
          }
    }
    

    return (
        <>
        <div className=" bg-side-color pt-5">
            <div className="row p-0 m-0">
                <div className="col-md-6 d-flex justify-content-center flex-column align-items-center">
                    <img src="/Images/land-dev2.png" className="w-50 " alt="" />
                    <div className="d-flex w-100 justify-content-center align-items-center mt-3">
                         <Link className="btn btn-warning px-5 py-2 me-4" to={"/layout/courses/offeredcourses"}>Enroll Now</Link>
                        <Link className="d-flex align-items-center btn py-2 me-4 border justify-content-center text-white">
                            <img src="/Images/call.png" className="hat " alt="" />
                            <p className="m-0 mb-1 ms-2">Contact Us</p>
                        </Link>
                    </div>

                </div>
                <div className="col-md-6 d-flex justify-content-center position-relative">
                    <div className=" position-absolute rounded-4 bg-white land-small-dev-stu text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <p className="m-0 ">10K+</p>
                            <p className="m-0 ">Student Enrolled</p>
                        </div>
                    </div>
                    <div className=" position-absolute rounded-4 bg-white land-small-dev-inst text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <p className="m-0 ">300K+</p>
                            <p className="m-0 ">Professional Instructors</p>
                        </div>
                    </div>
                    <div className=" position-absolute rounded-4 bg-white land-small-dev-course text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <img src="/Images/hat.png" className="hat" alt="" />
                            <p className="m-0 ">1K+</p>
                            <p className="m-0 ">Online Courses</p>
                        </div>
                    </div>
                    <img src="/Images/land-dev.png" className="w-50 land-img " alt="" />
                    
                </div>
            </div>
            
        </div>
        <div className="m-0 p-0 background">
            <div className="position-relative background">        
                <img src="/Images/line.png" className="w-100 m-0 p-0 position-absolute line1" alt="" />
                <img src="/Images/line2.png" className="w-100 m-0 p-0 position-absolute line2" alt="" />
            </div>

        </div>
        <div className="position-relative after-line ">
        </div>
        <div className="flex-row background d-flex mt-2">
            <div className="col-md-6 d-flex flex-column align-items-center justify-content-start ">
                <div className="me-2 bg-side-color w-75 land-smal-dev rounded-5  mt-1 p-3 d-flex align-items-center justify-content-center">
                    
                    <img src="/Images/land3.png" className="land-dev-img" alt="" />
                    <div className="d-flex flex-column ms-4 align-items-center ">
                        <p className="text-warning w-100 m-0 mb-1">
                            Experienced Instructors
                        </p>
                        <p className="text-white m-0 ">
                            Our instructors will always help you out and will never leave you until you are capable of analyzing, determining, and solving Exam papers and also focuses on making you understand the entire syllabus.
                        </p>
                    </div>
                </div>
                <div className="me-2 border w-75 land-smal-dev rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center">
                    
                    <img src="/Images/land4.png" className="land-dev-img" alt="" />
                    <div className="d-flex flex-column ms-4 align-items-center ">
                        <p className="text-warning w-100 m-0 mb-1">
                            Payment Facilities & Installment Systems
                        </p>
                        <p className=" m-0 ">
                            We discuss the payment facilities and installments with the parent/student until we reach a meeting point to make it affordable for parents/students to pay comfortably.
                        </p>
                    </div>
                </div>
                <div className="me-2 border w-75 land-smal-dev rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center">
                    
                    <img src="/Images/land5.png" className="land-dev-img" alt="" />
                    <div className="d-flex flex-column ms-4 align-items-center ">
                        <p className="text-warning w-100 m-0 mb-1">
                            Academic Advisor
                        </p>
                        <p className=" m-0 ">
                            There is an Academic advisor always in service to support you and advise you on what is most likely the best for your educational IGCSE career.
                        </p>
                    </div>
                </div>
            
            </div>
            <div className="col-md-6 d-flex flex-column  justify-content-start ">
                <div className="me-2 border w-75 land-smal-dev rounded-5 mt-1  p-3 d-flex align-items-center justify-content-center">
                    
                    <img src="/Images/land4.png" className="land-dev-img" alt="" />
                    <div className="d-flex flex-column ms-4 align-items-center ">
                        <p className="text-warning w-100 m-0 mb-1">
                            Free Trial sessions
                        </p>
                        <p className="text-black m-0">
                            We provide students with trial sessions that are completely free for them to decide whether they are comfortable continuing the session with the teacher or not.
                        </p>
                    </div>
                </div>
                <div className="me-2 border land-smal-dev w-75 rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center">
                    
                    <img src="/Images/land5.png" className="land-dev-img" alt="" />
                    <div className="d-flex flex-column ms-4 align-items-center ">
                        <p className="text-warning w-100 m-0 mb-1">
                            Live and Recorded sessions
                        </p>
                        <p className="text-black m-0">
                            Most courses have live & recorded sessions for students to have quick recaps whenever they want to.
                        </p>
                    </div>
                </div>
            
            </div>
           
        </div>
        <div className="flex-row background d-flex py-5">
            <div className="col-md-6 mt-3 d-flex justify-content-end align-items-center">
                <div className="w-75 ">
                    <h3 className="main-color">
                        we strive for your success!
                    </h3>
                    <p className="m-0">
                        At IG Way, our primary emphasis is on hiring educators with extensive expertise and exceptional teaching abilities. Our dedicated instructors work tirelessly to provide personalized guidance to each student, ensuring they receive round-the-clock support until they successfully achieve their desired goals.
                    </p>
                    <div className="d-flex align-items-center justify-content-between  mt-4">    
                        <div className="d-flex flex-column align-items-center ms-5 ">
                            <p className="m-0 fw-bolder">10,000</p>
                            <p> Students Enrolled</p>
                        </div>
                    <div className="d-flex flex-column align-items-center">
                        <p className="m-0 fw-bolder">1,250</p>
                        <p> Courses</p>
                    </div>
                    <div className="d-flex flex-column align-items-center me-5">
                        <p className="m-0 fw-bolder">6</p>
                        <p> Countries</p>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-6 mt-3 d-flex  justify-content-center">
                <img src="/Images/students.png" className="w-75" alt="" />
                
            </div>
        </div>
        <div className="background py-5">
            <div className="d-flex flex-column align-items-center justify-content-center w-50 mx-auto ">
                <h2 className="alltext-side-color">
                    Our Courses
                </h2>
                <p className=" opacity-75 m-0">
                    Unlock the opportunity to inspire and educate by joining our team of instructors. 
                </p>
                <p className=" opacity-75 ">
                    Elevate your impact and share your experience
                </p>
            </div>
        </div>
        <div className="flex-row d-flex background px-5">
        {homeCourses.length > 0 ? (
            <div className="col-md-12 px-4 background">
              <div className="row">
                <Slider {...settings}>
                  {homeCourses.map((course, index) => (
                    <CourseHomeItem course={course} key={index} />
                  ))}
                </Slider>
              </div>
            </div>
          ) : (
            <div className="col-md-12 px-4 background">
              <div className="row rounded-3 border py-4">
                <div className="col-md-6  text-center">
                  <img src="/Images/course-div.png" className="" alt="" />
                </div>
                <div className="col-md-6 p-3 px-4 d-flex flex-column justify-content-center align-items-center">
                  <h4>{t("startExploring") }</h4>
                  <h4>{t("learningJourney")}</h4>
                  <button className="btn btn-warning mt-4 px-2 py-2 w-25 fw-bold">
                    {t("startExloring") }
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center background">
            <img src="/Images/testimonial.png" className="w-50" alt="" />
        </div>
        <div className=" position-relative instru-dev">
            
            <div className="flex-row background d-flex pb-5">
                <div className="col-md-6 mt-3 d-flex justify-content-end align-items-center ps-5">
                    <div className="w-75 ">
                        <h3 className="main-color">
                            If you are a certified teacher 
                        </h3>
                        <h3 className="main-color">
                            then <span className="alltext-side-color">become an instructor</span>  
                        </h3>
                        <p className="m-0">
                            Unlock the opportunity to inspire and educate by joining our team of instructors. Elevate your impact and share your experience!
                        </p>
                        <Link className="btn btn-warning px-2 py-2 mt-4">Become an instructor</Link>
                    </div>
                </div>
                <div className="col-md-6 mt-3 d-flex  justify-content-center">
                    <img src="/Images/instructor-img.png" className="w-50" alt="" />
                    
                </div>
            </div>
            <img src="/Images/line3.png" className="w-100 m-0 p-0 position-absolute line3" alt="" />
            <img src="/Images/line4.png" className="w-100 m-0 p-0 position-absolute line3" alt="" />
        
        </div>
        <div className=" after-line2">
        </div> 
        </>
    );
}
