import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


export default function CourseOfferItem({ course }) {
  const { t } = useTranslation();
  return  <>
  <div className="col-md-4 p-2 mt-3" key={course.id}>
    <div>
        <div className="bg-white shadow text-center p-3 rounded-3">
            <img src={course.image.url} className="w-100 home-course" alt="" />
        </div>
        <p className="  mt-3 h4"> {course.name}</p>
        <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 opacity-50 h5 ">{course.teacher.user.fullName}</p>
            <div className="d-flex align-items-center me-3">
                <img src="/Images/star.png" alt="" />
                <p className="opacity-50 ms-1  m-0">{Math.round(course.rate)}</p>
            </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex mt-3 m-0">
                      <h5 className="opacity-50 mx-2">{course.currency }</h5>
                      <h5 className="">{ course.price}</h5>
            </div>
            {course.offer?<div className="px-2 sale_background py-1 rounded-3 me-3">
                <p className=" text-danger m-0">{course.offer.type="percentage"?"%":course.offer.type}{course.offer.value}</p>
            </div>:""}
            
        </div>
        <div className=" position-relative mt-2">
            {course.completionDetails==="completed"?<Link to={`/layout/coursedetails/${course.id}`}>
                      <button className=" border-0 bg-enrooled-course py-3 w-100 fw-bold rounded-start-4 rounded-end-5 start-course">{ t("completed")}</button>
                <div className=" completed-arrow bg-warning rounded-circle shadow p-4">
                    <div className=" w-100 h-100  d-flex justify-content-center align-items-center">
                        <img src="/Images/completed.png" alt="" />
                    </div>
                </div>
            </Link>:""}
            {course.completionDetails !=="completed" && course.completionDetails!==null?<Link className=" text-decoration-none text-dark " to={`/layout/coursedetails/${course.id}`}>
            <div className=" border-0 bg-enrooled-course w-100 pb-1 p-1 rounded-start-4 rounded-end-5 start-course text-start">
              <p className="p-0 m-0 ms-2 text-enroll fw-bold">Lessons {course.completionDetails.completed} /{" "} {course.completionDetails.total}</p>
            <div className="d-flex align-items-center">
                
                <div className="progress my-2 w-60 ms-2" style={{ height: '10px', backgroundColor: '#FFFFFFE6' }}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${course.completionDetails.completed/course.completionDetails.total*100}%` }}></div>
                </div>
                <p className="p-0 m-0 ms-2 text-enroll fw-bold">{Math.round(course.completionDetails.completed/course.completionDetails.total * 100)}%</p>
            </div>
            
            </div>
            <div className="bg-white go-arrow rounded-circle p-2 shadow">
              <div className=" w-100 h-100 border rounded-circle border-warning border-2 d-flex justify-content-center align-items-center">
                <img src="/Images/arrow-white.png" alt="" />
              </div>
            </div>
          </Link>:""}
            {course.completionDetails !=="completed" && course.completionDetails===null?<Link to={`/layout/coursedetails/${course.id}`}>
            <button className="btn btn-warning py-3 w-100 fw-bold rounded-start-4 rounded-end-5 start-course">{ t("startCourse")}</button>
            <div className="bg-white go-arrow rounded-circle p-2 shadow">
                <div className=" w-100 h-100 border rounded-circle border-warning border-2 d-flex justify-content-center align-items-center">
                    <img src="/Images/arrow-white.png" alt="" />
                </div>
            </div>
        </Link>:""}
        
        </div>
    </div>
  </div>
  
</>
}
