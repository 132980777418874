import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';
import 'react-phone-input-2/lib/style.css';
import 'react-calendar/dist/Calendar.css';
import './i18n';
import { PriceProvider } from './PriceContext.js';
import { HelmetProvider } from 'react-helmet-async';
import $ from 'jquery';
import { WalletProvider } from './WalletContext.js';
window.$ = window.jQuery = $;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WalletProvider>
    <PriceProvider>
    <HelmetProvider>
       <App />
    </HelmetProvider>
    </PriceProvider>
    </WalletProvider>
  </React.StrictMode>
);

reportWebVitals();
