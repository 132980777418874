import { useState,useCallback } from 'react'
import axios from 'axios'
export const usePost = (url) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const makeRequest = useCallback(async (requestData) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const {response} = await axios.post(url, requestData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                  },
            });
            setData(response);
            console.log('Request success: ', response);
        } catch (error) {
            setError(error.response ? error.response : error.message);
            console.log('Request error: ', error.response ? error.response : error.message);
        } finally {
            setLoading(false);
        }
    }, [url]);
    
  return ({ loading, error, data, makeRequest });
}
