import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import ReactHelmet from "../ReactHelmet/ReactHelmet";

export default function PersonalInfo() {
  const { t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [errors, setErrors] = useState();

  async function getCountries() {
    let { data } = await axios.get(`https://back.ig-way.com/api/countries`, {
      headers: {
        lang: `en`,
      },
    });
    setCountries(data.data);
  }

  useEffect(() => {
    getCountries();
  }, []);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    middle_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    country_id: Yup.string().required(t("required")),
    phone: Yup.string().required(t("required")),
    phone_code: Yup.string().required(t("required")),
    gender: Yup.string().required(t("required")),
    birth_date: Yup.string().required(t("required")),
    nationality_id: Yup.string().required(t("required")),
    parent_phone: Yup.string().required(t("required")),
    parent_phone_code: Yup.string().required(t("required")),
  });

  const handleNext = async (values) => {
    try {
      setIsloading(true);
      let { data } = await axios.post(
        `https://back.ig-way.com/api/auth/student/validate-first-form`,
        values
      );
      navigate("/logininfo");
      localStorage.setItem("formValues", JSON.stringify(values));
    } catch (error) {
      setIsloading(false);
      setErrors(error.response.data.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      country_id: "",
      phone: "",
      phone_code: "",
      gender: "",
      birth_date: "",
      nationality_id: "",
      parent_phone: "",
      parent_phone_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });

  const handlePhoneChange = (value, country) => {
    const phone_code = country.dialCode;
    const phoneNumber = value.slice(phone_code.length);
    formik.setFieldValue("phone_code", phone_code);
    formik.setFieldValue("phone", phoneNumber);
  };
  const handlePhoneParentChange = (value, country) => {
    const phone_code = country.dialCode;
    const phoneNumber = value.slice(phone_code.length);
    formik.setFieldValue("parent_phone_code", phone_code);
    formik.setFieldValue("parent_phone", phoneNumber);
  };

  useEffect(() => {
    // Add or remove the 'arabic' class to the body based on the current language
    if (i18n.language === 'ar') {
        document.body.classList.add('arabic');
    } else {
        document.body.classList.remove('arabic');
    }
}, [i18n.language]);

  return (
    <div className="container-fluid p-0 m-0">
      <ReactHelmet title={`${t('signup')} | IG WAY`} /> 

      <div className="row p-0 m-0">
        <div className="col-md-6 p-0 m-0">
          <img
            src="/Images/reg-image2.png"
            className="w-100 h-100 bg-side-color p-0 m-0 rounded-end-4  signupImg"
            alt=""
          />
        </div>
        <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
            <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
            <img src="/Images/reg-logo.png" className="mx-5 mt-2" alt="" />
          </div>
          <div className="row authenticationBody">
            <div className="col-md-10 offset-md-1">
              <h1 className="ms-5 mb-3">{t('signup')}</h1>
              <form className="row mt-4" onSubmit={formik.handleSubmit}>
                {errors && (
                  <p className="text-danger alert alert-danger offset-1 col-md-10">
                    <i class="fa-solid fa-asterisk text-danger me-2"></i>
                    {errors}
                  </p>
                )}
                <div className="col-md-5 offset-md-1 ps-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-fill d-flex ms-3 flex-column">
                          <img src="/Images/person-icon.png" alt="Person Info" />
                        </div>
                        <p className="mt-2 fw-lighter personal-info">{t("personalInfo")}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-unfill d-flex ms-3 flex-column">
                          <img src="/Images/login-icon.png" alt="login" />
                        </div>
                        <p className="mt-2 fw-lighter">{t("loginInfo")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="first_name" className="form-label">
                      {t("firstName")}
                    </label>
                    <div className="input-container">
                      <input
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                        className={`form-control ${i18n.language === 'ar' ? 'pe-3' : ''}`}
                        name="first_name"
                        placeholder={t("firstName")}
                        aria-describedby="emailHelp"
                      />
                      {formik.touched.first_name &&
                        formik.errors.first_name && (
                          <div
                            className="error-icon"
                            title={formik.errors.first_name}
                          >
                            <i className="fa-solid fa-circle-exclamation text-danger"></i>
                            <div className="tooltip">
                              {formik.errors.first_name}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="last_name" className="form-label">
                    {t("lastName")}
                    </label>
                    <div className="input-container">
                      <input
                        type="text"
                        name="last_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                        className="form-control"
                        placeholder={t("lastName")}
                      />
                      {formik.touched.last_name && formik.errors.last_name && (
                        <div
                        className="error-icon"
                        title={formik.errors.last_name}
                        >
                          <i className="fa-solid fa-circle-exclamation text-danger"></i>
                          <div className="tooltip">
                            {formik.errors.last_name}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="country_id" className="form-label">
                      {t("country")}
                    </label>
                    <select
                      className="form-select"
                      name="country_id"
                      onChange={formik.handleChange}
                      value={formik.values.country_id}
                      onBlur={formik.handleBlur}
                      aria-label="Country select"
                    >
                      <option value="" label={t("chooseCountry")} />
                      {countries.map((country, index) => (
                        <option key={index} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.country_id && formik.errors.country_id && (
                      <div className="alert alert-danger p-1">
                        {formik.errors.country_id}
                      </div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">
                      {t("whatsapp")}
                    </label>
                    <div className="input-container">
                      <PhoneInput
                        onChange={handlePhoneChange}
                        inputStyle={{ width: "100%" }}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        onBlur={formik.handleBlur}
                        country={"eg"}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className="error-icon">
                          <i className="fa-solid fa-circle-exclamation text-danger"></i>
                          <div className="tooltip">{formik.errors.phone}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <p className="mt-2">{t("gender")}</p>
                  <div className="w-100 d-flex mb-4">
                    <div className="form-check me-5">
                      <input
                        className="form-check-input"
                        value="female"
                        onChange={formik.handleChange}
                        checked={formik.values.gender === "female"}
                        type="radio"
                        name="gender"
                        id="flexRadioDefault1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                      >
                        {t("female")}
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="male"
                        onChange={formik.handleChange}
                        checked={formik.values.gender === "male"}
                        name="gender"
                        id="flexRadioDefault2"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        {t("male")}
                      </label>
                    </div>
                  </div>
                  {formik.touched.gender && formik.errors.gender && (
                    <div className="alert alert-danger p-1">
                      {formik.errors.gender}
                    </div>
                  )}
                </div>
                <div className="col-md-5 ps-0">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="w-100 ms-4 iconsBar">
                        <div className="box bg-color-unfill d-flex flex-column">
                          <img src="/Images/verify.png" alt="verify" />
                        </div>
                        <p className="mt-2 fw-lighter">{t("verify")}</p>
                      </div>
                    </div>
                    <div className="col-md-6 ps-0">
                      <div className="w-100 ms-3 iconsBar">
                        <div className="box bg-color-unfill d-flex ms-3 flex-column last-box">
                          <img src="/Images/edu-login.png" alt="education system"/>
                        </div>
                        <p className="mt-2 fw-lighter  edu-system">{t("eduSystem")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="middle_name" className="form-label">
                      {t("middleName")}
                    </label>
                    <div className="input-container">
                      <input
                        type="text"
                        name="middle_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.middle_name}
                        className="form-control"
                        placeholder={t("middleName")}
                        aria-describedby="emailHelp"
                      />
                      {formik.touched.middle_name &&
                        formik.errors.middle_name && (
                          <div
                            className="error-icon"
                            title={formik.errors.middle_name}
                          >
                            <i className="fa-solid fa-circle-exclamation text-danger"></i>
                            <div className="tooltip">
                              {formik.errors.middle_name}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="birth_date" className="form-label">
                      {t("birthdate")}
                    </label>
                    <div className="input-container">
                      <input
                        type="date"
                        name="birth_date"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.birth_date}
                        className="form-control"
                      />
                      {formik.touched.birth_date &&
                        formik.errors.birth_date && (
                          <div
                            className="error-icon"
                            title={formik.errors.birth_date}
                          >
                            <i className="fa-solid fa-circle-exclamation text-danger"></i>
                            <div className={`tooltip ${i18n.language === 'ar' ? 'text-end' : ''}`}>
                              {formik.errors.birth_date}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="nationality_id" className="form-label">
                      {t("nationality")}
                    </label>
                    <select
                      className="form-select"
                      name="nationality_id"
                      onChange={formik.handleChange}
                      value={formik.values.nationality_id}
                      onBlur={formik.handleBlur}
                      aria-label="Nationality select"
                    >
                      <option value="" label={t("chooseCountry")} />
                      {countries.map((country, index) => (
                        <option key={index} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.touched.nationality_id &&
                      formik.errors.nationality_id && (
                        <div className="alert alert-danger p-1">
                          {formik.errors.nationality_id}
                        </div>
                      )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="parent_phone" className="form-label">
                      {t("parentWhatsapp")}
                    </label>
                    <div className="input-container">
                      <PhoneInput
                        onChange={handlePhoneParentChange}
                        inputStyle={{ width: "100%" }}
                        inputProps={{
                          name: "parent_phone",
                          required: true,
                        }}
                        onBlur={formik.handleBlur}
                        country={"eg"}
                      />
                      {formik.touched.parent_phone &&
                        formik.errors.parent_phone && (
                          <div
                            className="error-icon"
                            title={formik.errors.parent_phone}
                          >
                            <i className="fa-solid fa-circle-exclamation text-danger"></i>
                            <div className="tooltip">
                              {formik.errors.parent_phone}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                  <div className="col-md-10 offset-md-1 pe-0">
                    {isloading ? (
                      <button
                        type="button"
                        className="btn btn-warning w-100 p-3 m-0"
                      >
                        <i className="fas fa-spinner fa-spin"></i>
                      </button>
                    ) : (
                      <button
                        disabled={!(formik.isValid && formik.dirty)}
                        type="submit"
                        className="btn btn-warning w-100 p-3 m-0"
                      >
                        {t("next")}
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <div className="w-100 text-center">
                <p className="mt-3">
                  {t("haveAccount")}{" "}
                  <span
                    className="text-warning pointer"
                    onClick={() => navigate("/login")}
                  >
                    {t("signin")}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
