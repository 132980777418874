import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { usePrice } from '../../../PriceContext.js';
import { usePost } from '../../usePost.js';
import { useWallet } from '../../../WalletContext.js';
export default function IGWallet({course}) {
  const [showModal, setShowModal] = useState(false);
  const [checkoutData, setCheckoutData] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user") || '{}');
  const userId = user.id || null;
  const {wallet } = useWallet();
  const { makeRequest, loading, error } = usePost("https://back.ig-way.com/api/checkout");

  const { price, selectedItems, withAssignments, type, newSelectedItemsIds, updateCheckout } = usePrice();


  // Memoize checkout data to prevent unnecessary re-renders
  const memoizedCheckoutData = useMemo(() => {
    if (!userId || price === undefined) return null;

    let newCheckoutData = {
      type: type,
      payment_method: 1,
      user_id: userId,
      with_assignments: withAssignments,
      value: price,
    };

    if (type === 1) { // Course
      newCheckoutData = {
        ...newCheckoutData,
        course_id: selectedItems.course?.id, // Select course ID
      };
    } else if (type === 2) { // Topics
      newCheckoutData = {
        ...newCheckoutData,
        topics: newSelectedItemsIds,
      };
    } else if (type === 3) { // Subcourses
      newCheckoutData = {
        ...newCheckoutData,
        subcourses: newSelectedItemsIds,
      };
    } else if (type === 4) { // Lessons
      newCheckoutData = {
        ...newCheckoutData,
        lessons: newSelectedItemsIds,
      };
    }

    return newCheckoutData;
  }, [type, selectedItems, withAssignments, price, userId, newSelectedItemsIds]);

  useEffect(() => {
    if (memoizedCheckoutData) {
      setCheckoutData(memoizedCheckoutData);
    }
  }, [memoizedCheckoutData]);

  const handleCheckout = async () => {
    if (checkoutData) {
      console.log("Checkout Data:", checkoutData);
      console.log("Selected Items:", selectedItems);
      await makeRequest(checkoutData);
      navigate(`/layout/coursedetails2/${course.id}`);
    } else {
      console.error("Checkout data is null or undefined.");
    }
  };

  const handleShowModal = () => {
    if (price <= wallet) {
      handleCheckout();
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const rechargeWallet = () => {
    navigate("/layout/wallet");
  };

  return (
    <>
      <div className="container mt-4 p-5 d-flex flex-column align-items-center bg-white rounded-4">
        <h2 className='py-3 alltext-side-color'>{t("withdrawal")}</h2>
        <img src="/Images/Group.png" alt="withdrawal" />
        <h5 className='pt-3 alltext-black-color '>{t("withdrawalText")} ${price.toFixed(2)} {t("withdrawalText2")}</h5>
        <div className='d-flex w-100 justify-content-between px-5 pt-3'>
          {loading ? (
            <button className='fw-bold btn btn-warning IGWallet-btn alltext-side-color shadow' disabled><i className="fa-solid fa-spinner fa-spin"></i></button>
          ) : (
            <button className='fw-bold btn btn-warning IGWallet-btn alltext-side-color shadow' onClick={handleShowModal}>
              {t("recharge")}
            </button>

          )}
          <button className='fw-bold btn btn-outline-secondary IGWallet-btn alltext-side-color shadow'>{t("cancel")}</button>
          {error && <p className="text-danger text-center">{error}</p>}
        </div>
        {price > wallet && showModal && (
          <div className="modal fade show d-block igWallet-modal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-4">
                <div className="modal-header justify-content-evenly">
                  <h5 className="modal-title ms-5 me-5" id="exampleModalLongTitle">{t("notCompletedPayment")}</h5>
                  <button type="button" className="close btn btn-main-color text-white fw-bold px-1 py-0 rounded ms-2" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true" className='p-1'>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-column align-items-center">
                    <img src="/Images/igwallet-girl.png" alt="No Money" />
                    <p className="alltext-black-color text-center">{t("notCompletedText")}</p>
                  </div>
                  <div className="modalBtns row">
                    <div className="col-md-6 pe-2">
                      <button className="btn btn-warning w-100 p-2 alltext-side-color fw-bold" onClick={rechargeWallet}>{t("charge")}</button>
                    </div>
                    <div className="col-md-6 ps-0">
                      <button className="btn btn-outline-secondary w-100 p-2 alltext-side-color fw-bold" onClick={handleCloseModal}>{t("anotherPayment")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
