import React from 'react';
import moment from 'moment';

export default function CustomEvent({ event }) {
  const formattedTimeRange = `${moment(event.start).format('h')} - ${moment(event.end).format('h A')}`;

  return (
    <>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className='rbc-event-content'>{event.course}</div>
      <div className='rbc-event-content'>{event.title}</div>
      <div className='rbc-event-time' style={{color:"#7B7B7B", fontSize:"14px",fontWeight:"600"}}>{formattedTimeRange}</div>
      </div>
    </>
  );
}
