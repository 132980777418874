import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function LoginInfo() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState([]);
  const [errors, setErrors] = useState();
  const [isloading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);



  useEffect(() => {
    const storedValues = localStorage.getItem('formValues');
    if (storedValues) {
      setInitialValues(JSON.parse(storedValues));
    }
  }, [])


  let validationSchema=Yup.object().shape({
    email: Yup.string().email(t('required')).required(t('required')),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
        t("validation")
      )
      .required(t('required')),  
    password_confirmation: Yup.string().oneOf([Yup.ref("password")]).required(t('required'))
  });
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: validationSchema
    ,
    onSubmit: handleNext
  });
  async function handleNext(values) {
    setIsloading(true);
    
    let formInfo = { ...initialValues, ...values };
    let { data } = await axios.post(`https://back.ig-way.com/api/auth/student/register`, formInfo)
      .catch(err => {
        setIsloading(false);
        setErrors(err.response.data.message)
      });
    console.log(data);

    navigate('/verify', { state: { email: values.email,id:data.data.id } });
  }
  useEffect(() => {
    // Add or remove the 'arabic' class to the body based on the current language
    if (i18n.language === 'ar') {
        document.body.classList.add('arabic');
    } else {
        document.body.classList.remove('arabic');
    }
}, [i18n.language]);

  return <>
  <div className=" container-fluid p-0 m-0 ">
      <ReactHelmet title={`${t('signup') } | IG WAY`} />
      
    <div className="row p-0 m-0">
      <div className="col-md-6 p-0 m-0">
        <img src="/Images/reg-image3.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4  signupImg' alt="" />
      </div>
      <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
      <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
      <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
        </div>
        <div className='row authenticationBody'>
          <div className="col-md-10 offset-md-1 ">
            <h1 className=' ms-5 mb-3'>{t('signup')}</h1>
            <form className="row mt-4" onSubmit={formik.handleSubmit}>  
              <div className={`col-md-10 offset-md-1 ps-0 ${i18n.language === 'ar' ? 'w-100' : ''}`}>
                <div className="row">
                  <div className="col-md-3">
                    <div className='w-100 iconsBar'>
                      <div className="box bg-color-fill d-flex ms-4 flex-column">
                        <img src="/Images/person-icon.png" alt="Personal Info" />
                      </div>
                      <p className='mt-2 fw-lighter'>{t('personalInfo')}</p>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                  <div className='w-100 iconsBar'>
                      <div className="box bg-color-fill d-flex ms-3 flex-column">
                        <img src="/Images/login-icon.png" alt="" />
                      </div>
                      <p className='mt-2 fw-lighter'>{t('loginInfo')}</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className='w-100 iconsBar'>
                      <div className="box bg-color-unfill d-flex ms-3 flex-column">
                        <img src="/Images/verify.png" alt="" />
                      </div>
                      <p className='mt-2 fw-lighter ms-3'>{t('verify')}</p>
                    </div>
                  </div>
                  <div className="col-md-3 ">
                  <div className='w-100 iconsBar'>
                      <div className="box bg-color-unfill ms-3 last-box">
                        <img src="/Images/edu-login.png" className='' alt="" />
                      </div>
                      <p className='mt-2 fw-lighter  p-0 m-0 edu-system'>{t('eduSystem')}</p>
                    </div>
                  </div>
                </div>
                  
                  {errors && <div className="alert alert-danger">{errors}</div>}
               

                  <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    {t('Email')}
                    </label>
                    <div className="input-container">
                      <input
                        type="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="form-control"
                        name="email"
                        placeholder={t('Email')}
                        aria-describedby="emailHelp"
                      />
                      {formik.touched.email &&
                        formik.errors.email && (
                          <div
                            className="error-icon"
                            title={formik.errors.email}
                          >
                            <i className="fa-solid fa-circle-exclamation text-danger"></i>
                            <div className="tooltip">
                              {formik.errors.email}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t('Password')}
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="form-control pe-5"
                      placeholder={t('Password')}
                                  />
                                    
                    
                    <button
                      className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                      type="button "
                        onClick={(event) => { 
                          event.preventDefault();
                          setShowPassword(!showPassword)
                        } }
                    >
                      {showPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                    </button>
                  </div>
                </div>
                { formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1">{formik.errors.password}</div> : null }
                <div className="mb-3">
                  <label htmlFor="password_confirmation" className="form-label">{t('confirmPass')}</label>
                    <div className="input-group">
                  <input
                   type={showConfirmPassword ? 'text' : 'password'}
                   name='password_confirmation' 
                   onChange={formik.handleChange} 
                   onBlur={formik.handleBlur} 
                   value={formik.values.password_confirmation} 
                   className="form-control pe-5" 
                   placeholder={t('passConfirmationPlaceholder')}
                    />
                    <button
                      className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                      type="button "
                      onClick={(event) => { 
                        event.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      } }
                    >
                      {showConfirmPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                      </button>
                    </div>

                  
                
                  </div>
                { formik.touched.Parent_Phone && formik.errors.password_confirmation ? <div className="alert alert-danger p-1">{formik.errors.password_confirmation}</div> : null }
                  

              </div>
              
              <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>

                <div className="col-md-5 offset-md-1 pe-0">
                  <button onClick={()=>navigate('/')} className="btn border border-black p-3 w-100">{t('previous')}</button>
                  
                </div>
                <div className="col-md-5  pe-0">    
                    {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0"><i className="fas fa-spinner fa-spin"></i></button>
                    :<button type="submit" disabled={!(formik.isValid && formik.dirty)} className="btn btn-warning w-100 p-3 m-0">{t('next')}</button>}
                    
                </div>
              </div>
            </form>
            


            
          </div>
        </div>
      </div>
    </div>
  </div>
</>
}
