import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
    const [wallet, setWallet] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchWalletData = async () => {
        if (localStorage.getItem('token')) {
            try {
                const token = localStorage.getItem("token");
                const { data } = await axios.get('https://back.ig-way.com/api/user/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setWallet(data.data.student.wallet);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setError(err.response ? err.response.data.message : 'An error occurred');
                console.error(err);
            }
        };
    }
    
    useEffect(() => {
        fetchWalletData();
    }, [fetchWalletData]);


    return (
        <WalletContext.Provider value={{ wallet, setWallet, isLoading, error, fetchWalletData }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = () => {
    return useContext(WalletContext);
};
