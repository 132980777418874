import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHelmet from "../ReactHelmet/ReactHelmet";
import Loading from "../Loading/Loading";

export default function Profile() {
  const { t, i18n } = useTranslation();
  const [isloading, setIsloading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [countries, setCountries] = useState([]);
  const [initialSysteems, setInitialSystems] = useState([]);
  const [educationSystems, setEducationSystems] = useState([]);

  async function getEducationSystems() {
    let { data } = await axios.get(
      `https://back.ig-way.com/api/education-systems`
    );
    setEducationSystems(data.data);
  }
  async function getCountries() {
    let { data } = await axios.get(`https://back.ig-way.com/api/countries`, {
      headers: {
        lang: `en`,
      },
    });
    setCountries(data.data);
  }

  useEffect(() => {
    getProfileData();
    getCountries();
    getEducationSystems();
  }, []);
  const getProfileData = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        `https://back.ig-way.com/api/user/profile`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data.data);
      setUserInfo(data.data);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      setErrors(error.response.data.message);
      console.log(error);
    }
  };

  const handleNext = async (values) => {
    try {
      const token = localStorage.getItem("token");

      setSubmitLoading(true);
      let { data } = await axios.post(
        `https://back.ig-way.com/api/user/update-student-profile`,
        values,{
          headers: {
              Authorization: `Bearer ${token}`,
          },
      }
      );
      console.log(data);
      setSubmitLoading(false);
      // window.location.reload();
      
    } catch (error) {
      setSubmitLoading(false);
      setErrors(error.response.data.message);
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(t("required")),
    middle_name: Yup.string().required(t("required")),
    last_name: Yup.string().required(t("required")),
    country_id: Yup.string().required(t("required")),
    phone: Yup.string().required(t("required")),
    phone_code: Yup.string().required(t("required")),
    birth_date: Yup.string().required(t("required")),
    nationality_id: Yup.string().required(t("required")),
    education_system_id: Yup.string().required(t('required')),
    
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      country_id: "",
      phone: "",
      phone_code: "",
      birth_date: "",
      nationality_id: "",
      education_system_id: '',
      
    },
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });
  useEffect(() => {
    if (userInfo.student) {
      formik.setValues({
        first_name: userInfo.firstName || '',
        middle_name: userInfo.middleName || "",
        last_name: userInfo.lastName || "",
        country_id: userInfo.student.country.id || "",
        phone: userInfo.phone || "",
        phone_code: userInfo.phone_code|| "",
        birth_date:"2024-06-03",
        nationality_id: userInfo.student.nationality.id || "",
        education_system_id: userInfo.student.educationSystem.id||'',
      });
    }
  }, [userInfo]);

  const goBack = () => {
    navigate(-1);
  };
  const handlePhoneChange = (value, country) => {
    const phone_code = country.dialCode;
    const phoneNumber = value.slice(phone_code.length);
    formik.setFieldValue("phone_code", phone_code);
    formik.setFieldValue("phone", phoneNumber);
  };
  return (
    <>
      <ReactHelmet title={`${t('profile') } | IG WAY`} />
      {isloading === true ? (
        <Loading />
      ) : (
        <div className="container">
          <div className="d-flex mb-3 align-items-center">
            <i className="fa-solid fa-arrow-left backIcon" onClick={goBack}></i>
            <h2 className="mx-2">{t('profile')}</h2>
          </div>
          <div className="position-relative">
            <div className=" profile-img">
              <img src="/Images/def.jpg" className="w-100 rounded-circle border border-white border-3" alt="Profile"/>
              <img src="/Images/image-upload.png"className="img-upload w-25"/>
            </div>
          </div>
          <div className="mt-4 row bg-white border px-2 rounded-2">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className=" my-3">{t('personalInfo')}</h4>
              <button  type="button" className="btn d-flex align-items-center justify-content-center border"data-bs-toggle="modal" data-bs-target="#editProfileInfo">
                <p className="m-0 p-0 me-2">{t('edit')}</p>
                <img  src="/Images/edit.png" className="edit-icon m-0 p-0"alt="" />
              </button>

              <div className="modal fade" id="editProfileInfo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title fs-5" id="exampleModalLabel">
                          {t('editPersonalInfo')}
                      </h1>
                      <button type="button" className="btn-close"data-bs-dismiss="modal"aria-label="Close">
                        </button>
                    </div>
                    <form  onSubmit={formik.handleSubmit}>
                      <div className="modal-body">
                        <div className="d-flex">
                          <div className="mb-3 me-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              {t('firstName')}
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('firstName')} name="first_name"onChange={formik.handleChange} value={formik.values.first_name}onBlur={formik.handleBlur}/>
                          </div>
                          <div className="mb-3 me-2">
                            <label htmlFor="exampleFormControlInput1" className="form-label" >
                              {t('middleName')}
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('middleName')} name="middle_name" onChange={formik.handleChange}value={formik.values.middle_name}onBlur={formik.handleBlur}/>
                          </div>
                          <div className="mb-3 ">
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              {t('lastName')}
                            </label>
                            <input type="text" className="form-control" id="exampleFormControlInput1" placeholder={t('lastName')} name="last_name" onChange={formik.handleChange} value={formik.values.last_name} onBlur={formik.handleBlur}/>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="mb-3 me-2 w-50">
                            <label htmlFor="country_id" className="form-label">
                              {t("country")}
                            </label>
                            <select className="form-select" name="country_id"onChange={formik.handleChange} value={formik.values.country_id} onBlur={formik.handleBlur} aria-label="Country select">
                              <option value={userInfo.student.country.id} >
                                {userInfo.student.country.name}
                              </option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-3 me-2 w-50">
                            <label htmlFor="nationality_id" className="form-label">
                              {t("nationality")}
                            </label>
                            <select  className="form-select"
                              name="nationality_id" onChange={formik.handleChange} value={formik.values.nationality_id} onBlur={formik.handleBlur} aria-label="Nationality select" >
                              <option value={userInfo.student.nationality.id}>
                                {userInfo.student.nationality.name}
                              </option>
                              {countries.map((country, index) => (
                                <option key={index} value={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="d-flex">    
                            <div className="mb-3 w-50 me-2">
                            <label htmlFor="phone" className="form-label">
                                {t("whatsapp")}
                            </label>
                            <div className="input-container">
                                <PhoneInput onChange={handlePhoneChange} inputStyle={{ width: "100%" }}
                                inputProps={{
                                    name: "phone",
                                    required: true,
                                }}
                                onBlur={formik.handleBlur}
                                country={formik.values.phone_code}
                                placeholder={formik.values.phone}
                                value={formik.values.phone}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                <div className="error-icon">
                                    <i className="fa-solid fa-circle-exclamation text-danger"></i>
                                    <div className="tooltip">
                                    {formik.errors.phone}
                                    </div>
                                </div>
                                )}
                            </div>
                            </div>
                            <div className="mb-3 w-50">
                                <label htmlFor="birth_date" className="form-label">
                                {t("birthdate")}
                                </label>
                                <div className="input-container">
                                <input type="date" name="birth_date" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.birth_date} className="form-control" />
                                {formik.touched.birth_date &&
                                    formik.errors.birth_date && (
                                    <div className="error-icon" title={formik.errors.birth_date}>
                                        <i className="fa-solid fa-circle-exclamation text-danger"></i>
                                        <div className={`tooltip ${i18n.language === 'ar' ? 'text-end' : ''}`}>
                                        {formik.errors.birth_date}
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="nationality_id" className="form-label" >
                              {t("eduSystem")}
                            </label>
                            <select className="form-select" name="nationality_id" onChange={formik.handleChange}value={formik.values.education_system_id} onBlur={formik.handleBlur} aria-label="Education System select" >
                              <option value={userInfo.student.nationality.id}>
                                {userInfo.student.educationSystem.name}
                              </option>
                              {educationSystems.map((education_system, index) => (
                                <option key={index} value={education_system.id}>
                                  {education_system.name}
                                </option>
                              ))}
                            </select>
                          </div>
                      </div>
                      <div className="modal-footer">
                        
                        <button disabled={!(formik.isValid && formik.dirty)}
                          type="submit"
                          className="btn btn-warning w-100 fw-bold"
                          >
                          {submitLoading===true?<i className="fa-solid fa-spinner fa-spin"></i>:"submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-0" />
            <div className="col-md-4 mt-3">
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("fullName")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">{userInfo.fullName}</p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center ">
                  <p className="m-0 p-0">{t("Email")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">{userInfo.email}</p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("country")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">
                  {userInfo.student.country.name}
                </p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("phone")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">{userInfo.phone}</p>
              </div>
            </div>
            <div className="col-md-4 mt-3 offset-md-1">
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("DOB")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">
                  {userInfo.student.birth_date}
                </p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("eduSystem")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">
                  {userInfo.student.educationSystem.name}
                </p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("nationality")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">
                  {userInfo.student.nationality.name}
                </p>
              </div>
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("parentPhone")}</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">
                  {userInfo.student.parent_phone}
                </p>
              </div>
            </div>
          </div>
          <div className="my-4 row bg-white border px-2 rounded-2">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className=" my-3">{t("security")}</h4>
              <Link
                to={'/Layout/editpassword'}
                type="button"
                className="btn d-flex align-items-center justify-content-center border"
              >
                <p className="m-0 p-0 me-2">{t("edit")}</p>
                <img src="/Images/edit.png" className="edit-icon m-0 p-0"alt="Edit"/>
              </Link>
              
            </div>
            <hr className="m-0" />
            <div className="col-md-4 mt-3">
              <div className="mb-2">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0 p-0">{t("Password") }</p>
                </div>
                <p className="m-0 p-0 opacity-50 ">************</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
