import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function ForgetPassword() {
  const { t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [errors, setErrors] = useState();

  
  
    let validationSchema=Yup.object().shape({
      email: Yup.string().email(t('required')).required(t('required')),
    });
    const formik = useFormik({
      initialValues: {
        email: '',

      },
      validationSchema: validationSchema
      ,
      onSubmit: handleNext
    });
  async function handleNext(values) {
    
    setIsloading(true);
    // const token = localStorage.getItem('token');
    let {data} = await axios.post(`https://back.ig-way.com/api/auth/password/reset`, values)
      .catch(err => {
      setIsloading(false);
        setErrors(err.response.data.message);
    });
    console.log(data);
    navigate('/forgetpasswordverify', { state: { id: data.user_id , email: values.email } });
    }
  
    return <>
        <div className=" container-fluid p-0 m-0 ">
          <ReactHelmet title={`${t('forgetPass') } | IG WAY`} />

        <div className="row p-0 m-0">
            <div className="col-md-6 p-0 m-0">
            <img src="/Images/forgetpassword.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg' alt="" />
            </div>
            <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
            <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
                <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
            </div>
            <div className='row authenticationBody'>
                <div className="col-md-10 offset-md-1">
                <h1 className=' ms-5 mb-2'>{t("forgetPass")}</h1>
                <p className=' ms-5 mb-3 opacity-50'>{t("forgetPassText")}</p>
                {errors && <div className="alert alert-danger col-md-10 offset-md-1">{errors}</div>}
                <form className="row mt-4" onSubmit={formik.handleSubmit}>  
                    <div className="col-md-10 offset-md-1 ps-0">
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label">{t("Email")}</label>
                            <input type="email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="form-control" placeholder={t("Email")} aria-describedby="emailHelp"/>
                        </div>
                        { formik.touched.email && formik.errors.email ? <div className="alert alert-danger p-1">{formik.errors.email}</div> : null }
                                       
                    </div>
                    
                    
                    <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                    <div className="col-md-10 offset-md-1  pe-0">
                    
                        
                    {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0"><i className="fas fa-spinner fa-spin"></i></button>
                    :<button type="submit" disabled={!(formik.isValid && formik.dirty)}  className="btn btn-warning w-100 p-3 m-0">{t("send")}</button>}
                    
                    </div>
                    </div>
                </form>
                
    
    
                
                </div>
            </div>
            </div>
        </div>
        </div>
  </>
}
