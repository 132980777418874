import React from 'react'
import { Navigate } from 'react-router-dom'

export default function LayoutRoute({children}) {
    if (localStorage.getItem("token"))
    {
        return <Navigate to={"/layout/home"}/>
    }
    else
    {
        return children;
    }

}
