import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ReactHelmet from "../ReactHelmet/ReactHelmet";
export default function Courses() {
  const [activeTab, setActiveTab] = useState("offeredcourses");
  const { t } = useTranslation();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <ReactHelmet title={`${t("Courses")} | IG WAY`} />
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12">
            <ul className="nav nav-tabs fw-bolder">
              <li className="nav-item text-center w-25">
                <Link
                  className={`nav-link text-black ${activeTab === "offeredcourses" ? "active line" : ""}`}
                  onClick={() => handleTabClick("offeredcourses")}
                  to="offeredcourses"
                >
                  {t("courses")}
                </Link>
              </li>
              <li className="nav-item text-center w-25">
                <Link
                  className={`nav-link text-black ${activeTab === "enrolledcourses" ? "active line" : ""}`}
                  onClick={() => handleTabClick("enrolledcourses")}
                  to="enrolledcourses"
                >
                  {t("enrolledCourses")}
                </Link>
              </li>
              <li className="nav-item text-center w-25">
                <Link
                  className={`nav-link text-black ${activeTab === "completedcourses" ? "active line" : ""}`}
                  onClick={() => handleTabClick("completedcourses")}
                  to="completedcourses"
                >
                  {t("completedCourses")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
}
