import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function GuardianRegister() {
    const { t, i18n  } = useTranslation();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirmation: '',
    });
    const [errors, setErrors] = useState(null);
    const [isloading, setIsloading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
    let validationSchema = Yup.object().shape({
        name: Yup.string().required(t('required')),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        phone: Yup.string().required(t('required')),
        password: Yup.string()
            .matches(
                /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
                t('validation')
            )
            .required(t('required')),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('matchPassword'))
            .required(t('required'))
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleNext
    });

    const handlePhoneChange = (value, country) => {
        const phone_code = country.dialCode;
        const phoneNumber = value.slice(phone_code.length);
        formik.setFieldValue('phone_code', phone_code);
        formik.setFieldValue('phone', phoneNumber);
    };

    async function handleNext(values) {
        setIsloading(true);
        try {
            let { data } = await axios.post(`https://back.ig-way.com/api/auth/parent/register`, values);

            navigate('/parentverify', { state: { email: values.email, id: data.data.id } });
            console.log(data);
            
        } catch (err) {
            setIsloading(false);
            setErrors(err.response.data.message);
        }
    }

    return (
        <div className="container-fluid p-0 m-0">
        <ReactHelmet title={`${t('signup') } | IG WAY`} />
        
            <div className="row p-0 m-0">
                <div className="col-md-6 p-0 m-0">
                    <img src="/Images/parentsignup.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg' alt="" />
                </div>
                <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
                <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
                        <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
                    </div>
                    <div className='row authenticationBody'>
                        <div className="col-md-10 offset-md-1">
                            <h1 className='ms-5 mb-3'>{t('signup')}</h1>
                            {errors && <div className="alert alert-danger col-md-10 offset-md-1">{errors}</div>}
                            <form className="row mt-4" onSubmit={formik.handleSubmit}>
                                <div className="col-md-10 offset-md-1 ps-0">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">{t('fullName')}</label>
                                        <input type="text" name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} className="form-control" placeholder={t('name')} />
                                        {formik.touched.name && formik.errors.name ? <div className="alert alert-danger p-1">{formik.errors.name}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">{t('Email')}</label>
                                        <input type="email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="form-control" placeholder={t('Email')} />
                                        {formik.touched.email && formik.errors.email ? <div className="alert alert-danger p-1">{formik.errors.email}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="phone" className="form-label">{t('whatsapp')}</label>
                                        <PhoneInput
                                            onChange={handlePhoneChange}
                                            inputStyle={{ width: '100%' }}
                                            inputProps={{ name: 'phone', required: true }}
                                            onBlur={formik.handleBlur}
                                            country={'eg'}
                                        />
                                        {formik.touched.phone && formik.errors.phone ? <div className="alert alert-danger p-1">{formik.errors.phone}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">{t('Password')}</label>
                                        <div className="input-group">
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                className="form-control pe-5"
                                                placeholder={t('Password')}
                                            />
                                            <button
                                                className="btn border-0 position-absolute end-0 top-50 translate-middle-y"
                                                type="button"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setShowPassword(!showPassword);
                                                }}
                                            >
                                                {showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
                                            </button>
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1">{formik.errors.password}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password_confirmation" className="form-label">{t('confirmPass')}</label>
                                        <div className="input-group">
                                            <input
                                                type={showConfirmPassword ? 'text' : 'password'}
                                                name='password_confirmation'
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password_confirmation}
                                                className="form-control pe-5"
                                                placeholder={t('confirmPass')}
                                            />
                                            <button
                                                className="btn border-0 position-absolute end-0 top-50 translate-middle-y"
                                                type="button"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setShowConfirmPassword(!showConfirmPassword);
                                                }}
                                            >
                                                {showConfirmPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
                                            </button>
                                        </div>
                                        {formik.touched.password_confirmation && formik.errors.password_confirmation ? <div className="alert alert-danger p-1">{formik.errors.password_confirmation}</div> : null}
                                    </div>
                                </div>
                                <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                                <div className="col-md-10 offset-md-1 pe-0">
                                        {isloading ? (
                                            <button type="button" className="btn btn-warning w-100 p-3 m-0">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        ) : (
                                            <button type="submit" disabled={!(formik.isValid && formik.dirty)} className="btn btn-warning w-100 p-3 m-0">
                                                {t('signup')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
