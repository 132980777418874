import React, { useEffect, useState } from "react";
import $ from "jquery";
import RecordingItem from "../Courses/CourseItem/RecordingItem";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { Circles } from "react-loader-spinner";
import ReactHelmet from "../ReactHelmet/ReactHelmet";

export default function Recordings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [recordings, setRecordings] = useState();

  const [selectedCourse, setSelectedCourses] = useState([]);
  const handleCheckboxChange = (setter, value) => {
    setter((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  

  const getRecordings = async () => {
    setIsLoading(true);
    try {
        const token = localStorage.getItem("token");
        let { data } = await axios.get(
            `https://back.ig-way.com/api/user/recordings`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setRecordings(data.data);
        setIsLoading(false);
    } catch (err) {
        console.log(err);
    }
  };
  useEffect(() => {
    getRecordings();
  }, []);
  function handleSlideDown(event) {
    $(event.currentTarget).parent().find(".drop-list").slideToggle();

    // Toggle the arrow icon class to switch between down and up arrows
    $(event.currentTarget).find(".arrow-icon").toggleClass("up");
  }

  return (
    <>
      {isLoading === true ?
        <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> :
        <>
          <ReactHelmet title={`${t("recordings")} | IG WAY`} />
          
          <div className="row mt-3 ">
            <div className="col-md-3">
              <div className=" shadow p-3 rounded-2 bg-white mx-2">
                <div className="d-flex justify-content-between ">
                  <div className="d-flex align-items-center">
                    <img src="/Images/filter.png" className="   mx-2" alt="" />
                    <h5>{t("filter")}</h5>
                  </div>
                  <button
                    className="btn btn-link text-decoration-none text-warning"
                    onClick={() => {
                      setSelectedCourses([]);
                    }}
                  >
                    {t("reset")}
                  </button>
                </div>
                <hr className="w-75 mx-auto " />
                <form>

                  <div className="mb-3">
                    <label
                      onClick={handleSlideDown}
                      className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                    >
                      <p className="p-0 m-0">Course</p>
                      <img
                        src="/Images/arrow-down.png"
                        className="search arrow-icon"
                        alt=""
                      />
                    </label>
                    <div className="drop-list hidden px-3 bg-light py-2 rounded-2 ">
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Course 1</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Board 1"
                          onChange={() =>
                            handleCheckboxChange(setSelectedCourses, "Board 1")
                          }
                          checked={selectedCourse.includes("Board 1")}
                        />
                      </div>
                      <div className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                        <label className="form-check-label">Course 2</label>
                        <input
                          className="form-check-input rounded-circle"
                          type="checkbox"
                          value="Board 2"
                          onChange={() =>
                            handleCheckboxChange(setSelectedCourses, "Board 2")
                          }
                          checked={selectedCourse.includes("Board 2")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-100 text-center">
                    <button
                      type="submit"
                      className="btn border border-2 border-black  w-50 mx-auto"
                    >
                      {t("apply")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row me-2">
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="d-flex align-items-center bg-white search-div shadow rounded-2 py-1 px-2 ">
                    <img src="/Images/search.png" className="search" alt="" />
                    <input
                      type="text"
                      className="form-control px-2 border-0"
                      placeholder={t("search")}
                      />
                  </div>
                  <div className="d-flex align-items-center me-4">
                    <img src="/Images/sort.png " className="mx-2" alt="" />
                    <p className="m-0 p-0 mx-2">{t("sort")}</p>
                  </div>
                      
                </div>
                <div className="row"></div>
                {recordings.length > 0 ?<> 
                  {recordings.map((recording,index) => {
                    return <RecordingItem recording={ recording}  key={index}/>
                   })} </>
                 : (
                  <div className="col-md-12 mt-2">
                    <div className="text-center">
                      <img src="/Images/Bookmarks-cuate.png" alt="" />
                      <h2 className="opacity-50">
                        {t("notSearched")}
                      </h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}
