import React from 'react'
import { Link } from "react-router-dom";


export default function LandingPageFooter() {
    return <>
        <div className=" bg-side-color py-4 ">
            <div className="flex-row d-flex">
                <div className="col-md-4">
                    <div className="d-flex flex-column justify-content-center align-items-start  w-50 mx-auto">
                        <div className=" w-100 text-center">
                        <img src="/Images/footer-logo.png" className="land-logo mb-3 " alt="" />
                        <h4 className="text-white  ">IG WAY</h4>
                        <h4 className="text-white">Follow us on</h4>
                        </div>
                        <div className="d-flex justify-content-between w-100 mt-4">
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center ">
                                <i className="fa-brands fa-facebook-f"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center ">
                                <i className="fa-brands fa-instagram"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center ">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center ">
                                <i className="fa-brands fa-youtube"></i>
                            </div>
                            
                        </div>
                        
                    </div>
                </div>
                <div className="col-md-8 text-white">
                    <h3>Do you have any questions?</h3>
                    <p className=" opacity-75">Leave a request and we will definitely answer you.</p>
                    <form action="">
                        <div className="d-flex">
                            <div className="mb-3 footer-input me-4">
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Full Name"/>
                            </div>
                            <div className="mb-3 footer-input">
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Enter Your Email Address "/>
                            </div>
                        </div>
                        <div className="form-group textarea-footer">
                            <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Write Your Question" rows="3"></textarea>
                            <div className="w-100 d-flex justify-content-end">
                                <Link className="btn btn-warning me-0 mt-3 px-5 ">Submit</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className=" bg-side-color d-flex justify-content-center align-items-center copywrite position-relative ">
            
           <p className="text-white mt-3">All Rights Reserved©2024 <span className="text-warning">IG WAY</span> </p>
        </div> 
    </>
}
