import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function EducationSystem() {
  const { t, i18n } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [initialSysteems, setInitialSystems] = useState([]);
  const [errors, setErrors] = useState();
  const navigate = useNavigate();
  

  async function getEducationSystems() {
    let { data } = await axios.get(`https://back.ig-way.com/api/education-systems`);
    setCountries(data.data);
    console.log(data.data);
  }

  useEffect(() => {
    getEducationSystems();
  }, []);
  const handleSubmit = async (values) => {
    setIsloading(true);
    const token = localStorage.getItem('token');
    let { data } = await axios.post(`https://back.ig-way.com/api/auth/student/select-education-system`, values, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).catch(err => {
      setIsloading(false);
      setErrors(err.response.data.message)
    });
    console.log(data);
    navigate('/layout/home');
    
    
  };

  const validationSchema = Yup.object().shape({
    education_system_id: Yup.string().required(t('required')),
  });

  const formik = useFormik({
    initialValues: {
      education_system_id: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });
  useEffect(() => {
    // Add or remove the 'arabic' class to the body based on the current language
    if (i18n.language === 'ar') {
        document.body.classList.add('arabic');
    } else {
        document.body.classList.remove('arabic');
    }
}, [i18n.language]);

  return (
    <div className="container-fluid p-0 m-0">
      <ReactHelmet title={`${t('signup') } | IG WAY`} />
      
      <div className="row p-0 m-0">
        <div className="col-md-6 p-0 m-0">
          <img src="/Images/reg-image1.png" className="w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg" alt="" />
        </div>
        <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
      <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
            <img src="/Images/reg-logo.png" className="mx-5 mt-2" alt="" />
          </div>
          <div className="row authenticationBody">
            <div className="col-md-10 offset-md-1">
              <h1 className="ms-5 mb-3">{t('signup')}</h1>
              <form className="row mt-4" onSubmit={formik.handleSubmit}>
                <div className="col-md-10 offset-md-1 ps-0">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-fill d-flex ms-4 flex-column">
                          <img src="/Images/person-icon.png" alt="Personal Info" />
                        </div>
                        <p className="mt-2 fw-lighter">{t('personalInfo')}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-fill d-flex ms-3 flex-column">
                          <img src="/Images/login-icon.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter">{t('loginInfo')}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-fill d-flex ms-3 flex-column">
                          <img src="/Images/verify.png" alt="" />
                        </div>
                        <p className="mt-2 ms-3 fw-lighter">{t('verify')}</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100 iconsBar">
                        <div className="box bg-color-fill d-flex ms-3 flex-column last-box">
                          <img src="/Images/edu-login.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter edu-system">{t('eduSystem')}</p>
                      </div>
                    </div>
                  </div>
                  {errors && <div className="alert alert-danger">{errors}</div>}
                    <div className="dropdown w-100">
                      <button className="btn border p-3  w-100 text-start d-flex justify-content-between " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {formik.values.education_system_id ? countries.find((country) => country.id === formik.values.education_system_id).name : t("selectEduSystem")}
                        <i className="fa-solid fa-caret-down opacity-50"></i>
                      </button>
                      <ul className="dropdown-menu w-100 p-0 edu-ul">
                      {countries.map((education_system, index) => (
                        <li key={index} value={education_system.id} onClick={() => { formik.setFieldValue('education_system_id', education_system.id); setInitialSystems(education_system.id) }} className="dropdown-item pointer option p-3">
                            {education_system.name}
                        </li>
                        ))}
                      </ul>
                      {formik.touched.education_system_id && formik.errors.education_system_id ? <div className="alert alert-danger p-1">{formik.errors.education_system_id}</div> : null}
                      <div className={`row p-0  ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                      <div className="col-md-12 pe-0 mt-5">
                        {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0"><i className="fas fa-spinner fa-spin"></i></button>
                        :<button disabled={!(formik.isValid && formik.dirty)} type="submit" className="btn btn-warning w-100 p-3 m-0">{t('submit')}</button>}
                        
                        
                        </div>
                      </div>
                    </div>
                  
                  
                </div>
            
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
