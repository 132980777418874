import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default function Sidebar() {
    const { t} = useTranslation();
  return (
    <>
     <div className="d-flex justify-content-center align-items-center flex-column mt-3">
       <img src="/Images/nav-logo.png" className="w-50 mt-4" alt="" />
     </div>
     <div className="text-white ms-2">
       <ul className="mt-5 list-unstyled">
         <li className="mt-3 ">
           <Link
             to={"home"}
             className=" text-white text-decoration-none "
           >
             <i className="fa-solid fa-house  mx-3"></i>
             {t("home")}
           </Link>
         </li>
         <li className="mt-3 ">
           <Link
             to={"courses/offeredcourses"}
             className=" text-white text-decoration-none "
             >
             <img src="/Images/category-icon.png" className="mx-3 " alt="category icon" />
             {t("myCourses")}
            
           </Link>
         </li>
         <li className="mt-3 ">
           <Link
             to={"schedule"}
             className=" text-white text-decoration-none "
             >
             <img src="/Images/calendar-icon.png" className="mx-3" alt="calendar icon" />
             
             {t("mySchedule")}
           </Link>
         </li>
         <li className="mt-3 ">
           <Link
             to={"recordings"}
             className=" text-white text-decoration-none "
             >
             <img src="/Images/record-icon.png" className="mx-3" alt="record icon" />
             
             {t("recordings")}
           </Link>
         </li>
         <li className="mt-3 ">
         <Dropdown>
              <Dropdown.Toggle variant="" id="dropdownMenuButton" className='text-white m-0 p-0 border-0'>
                <img src="/Images/transaction-icon.png" className="mx-3" alt="transaction icon" />
                {t("transactions")}
              </Dropdown.Toggle>
              <Dropdown.Menu className=' bg-none text-white'>
                <Dropdown.Item as={Link} to="attendance-transactions">{t("attendanceTransactions")}</Dropdown.Item>
                <Dropdown.Item as={Link} to="recharge-transactions">{t("rechargeTransactions")}</Dropdown.Item>
                <Dropdown.Item as={Link} to="wallet-refunds">{t("walletRefunds")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        </li>
       </ul>
     </div>
    </>
  )
}
