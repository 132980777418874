import axios from 'axios';
import { Circles } from "react-loader-spinner";
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export default function RecordingDetails() {
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [lesson, setLesson] = useState([]);
  const { id } = useParams();

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  const getRecording = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        `https://back.ig-way.com/api/user/lessons/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRecord(data.data.recording);
      setLesson(data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRecording();
  }, []);
  return <>
    { isLoading === true ?
      <div className="d-flex justify-content-center align-items-center spiner">
        <Circles
          height="80"
          width="80"
          color="#FFBC15"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div> :
      <div className=" container-fluid">
        <div className="row px-3">
          <div className="col-md-7">
            <div>
              <div className="d-flex align-items-center">
                <i className="fa-solid fa-arrow-left backIcon" onClick={goBack}></i>
                <h5 className="ms-5">Recordings</h5>
              </div>
            </div>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/DgXsPVI1wvA"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>
            {/* <img src={record.image.url} className="mt-3 w-100 course-details-img" alt="" /> */}
            <h3 className="mt-4">{lesson.name}</h3>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mt-4">{record.name} </h6>
              <h6 className="mt-4">{record.created_at} </h6>
            </div>
          </div>
          <div className="col-md-5">
            <h6>You also may want to watch</h6>
                
            <div className="bg-white rounded-3 p-3 shadow mt-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <img src="/Images/vedio-img.png" alt="" className='record-img me-2 rounded-4' />
                <div>
                  <p className='fw-bold'>
                    Learn UI UX & Be a professional in this field
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Ahmed Mahmoud</p>
                    <p>3 hours ago</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <img src="/Images/vedio-img.png" alt="" className='record-img me-2 rounded-4' />
                <div>
                  <p className='fw-bold'>
                    Learn UI UX & Be a professional in this field
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Ahmed Mahmoud</p>
                    <p>3 hours ago</p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <img src="/Images/vedio-img.png" alt="" className='record-img me-2 rounded-4' />
                <div>
                  <p className='fw-bold'>
                    Learn UI UX & Be a professional in this field
                  </p>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>Ahmed Mahmoud</p>
                    <p>3 hours ago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
  </>
}
