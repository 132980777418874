import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";

export default function UserNewPassword() {
  const { t, i18n } = useTranslation();
  const [isloading, setIsloading] = useState(false);
  const [errors, setErrors] = useState();
  const [succes, setSucces] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  let validationSchema=Yup.object().shape({
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
        t("validation")
      )
      .required(t('required')),  
    password_confirmation: Yup.string().oneOf([Yup.ref("password")]).required(t('required'))
  });
  async function handleNext(values) {
    console.log(values);
    setIsloading(true);
    let token = localStorage.getItem("token_verify");
    
    let send_data = {...values,"token":token}
    // console.log(values);
    try {
      
      let { data } = await axios.post(`https://back.ig-way.com/api/auth/password/update`, send_data);
      setSucces(data.message);
      setErrors(null);
      setIsloading(false);


      
    } catch (error) {
      setErrors(error.response.data.message ? error.response.data.message : error.response.data.errors.message[0]);
      setSucces(null);
      setIsloading(false);

      
      
    }
    
  }
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: validationSchema
    ,
    onSubmit: handleNext
  });
  // const allInputsFilled = formik.values.inputs.every(input => input.trim() !== '');

  return (
    <div className="container">
      <div className="d-flex mb-3 align-items-center">
        <i className="fa-solid fa-arrow-left backIcon" onClick={goBack}></i>
        <h4 className="mx-2">Create new password</h4>
      </div>
      <p>Your new password must be different to previously used passwords</p>
      <div className="border bg-white p-4 w-50 rounded-3">
        {/* <form action="" onSubmit={handleForgetPassword}> */}

        <form action="" onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            {succes != null ? <div className='alert alert-success'> {succes}</div> : ""}
            {errors != null ? <div className='alert alert-danger'> { errors}</div>:""}
          
            <label htmlFor="password" className="form-label forget-password">
              New Password
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                className="form-control pe-5"
                placeholder="Enter Your New Password"
              />

              <button
                className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                type="button "
                onClick={(event) => {
                  event.preventDefault();
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <i className=" fa-regular fa-eye"></i>
                ) : (
                  <i className=" fa-solid fa-eye-slash"></i>
                )}
              </button>
            </div>
          </div>
          {/* { formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1">{formik.errors.password}</div> : null } */}

          <div className="mb-3">
            <label
              htmlFor="password_confirmation"
              className="form-label forget-password"
            >
              {t("confirmPass")}
            </label>
            <div className="input-group">
              <input
                type={showConfirmPassword ? "text" : "password"}
                name="password_confirmation"
                   onChange={formik.handleChange}
                   onBlur={formik.handleBlur}
                   value={formik.values.password_confirmation}
                className="form-control pe-5"
                placeholder={t("passConfirmationPlaceholder")}
              />
              <button
                className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                type="button "
                onClick={(event) => {
                  event.preventDefault();
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              >
                {showConfirmPassword ? (
                  <i className=" fa-regular fa-eye"></i>
                ) : (
                  <i className=" fa-solid fa-eye-slash"></i>
                )}
              </button>
            </div>
          </div>
          {/* { formik.touched.Parent_Phone && formik.errors.password_confirmation ? <div className="alert alert-danger p-1">{formik.errors.password_confirmation}</div> : null } */}
          {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0 mt-5"><i className="fas fa-spinner fa-spin"></i></button>
            :
            <button type="submit" className="btn btn-warning w-100" disabled={!(formik.isValid && formik.dirty)}>
              {t("submit")}
            </button>}
        </form>
      </div>
    </div>
  );
}
