import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function CourseEnrolledItem({ course, key }) {
  const { t } = useTranslation();

  return (
    <div key={key} className="col-md-4 p-2 mt-3">
      <div>
        <div className="bg-white shadow text-center p-3 rounded-3">
          <img src={course.image.url} className="w-100 home-course" alt="" />
        </div>
        <p className="  mt-3 h4"> {course.name}</p>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0 opacity-50 h5 ">{course.teacher.user.fullName}</p>
        </div>
        <div className=" position-relative mt-2">
          <Link className=" text-decoration-none text-dark " to={`/layout/coursedetails/${course.id}`}>
            <div className=" border-0 bg-enrooled-course w-100 pb-1 p-1 rounded-start-4 rounded-end-5 start-course text-start">
              <p className="p-0 m-0 ms-2 text-enroll fw-bold">Lessons {course.completionDetails.completed} /{" "} {course.completionDetails.total}</p>
            <div className="d-flex align-items-center">
                
                <div className="progress my-2 w-60 ms-2" style={{ height: '10px', backgroundColor: '#FFFFFFE6' }}>
                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: `${course.completionDetails.completed/course.completionDetails.total*100}%` }}></div>
                </div>
                <p className="p-0 m-0 ms-2 text-enroll fw-bold">{Math.round(course.completionDetails.completed/course.completionDetails.total * 100)}%</p>
            </div>
            
            </div>
            <div className="bg-white go-arrow rounded-circle p-2 shadow">
              <div className=" w-100 h-100 border rounded-circle border-warning border-2 d-flex justify-content-center align-items-center">
                <img src="/Images/arrow-white.png" alt="" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
