import axios from "axios";
import React, { useEffect, useState , useCallback, useMemo} from "react";
import {  useParams } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { Link, useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactHelmet from "../../ReactHelmet/ReactHelmet";
import { useWallet } from '../../../WalletContext';
import {price} from '../../../PriceContext';
import Loading from "../../Loading/Loading";

export default function CourseDetails() {
  const {t} = useTranslation();
  const [course, setCourse] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [reviewloading, setReviewloading] = useState(false);
  const [reviewSubmit, setReviewSubmit] = useState(false);
  const [err, setErr] = useState("");
  const { id } = useParams();
  const [review, setReview] = useState('');
  const [rate, setRate] = useState(0);
  const navigate = useNavigate();
  const {wallet} = useWallet();
  const [message, setMessage] = useState("");
  const [visibleSection, setVisibleSection] = useState("overview");

  useEffect(() => {
    getCourse();
  }, []);

  // Memoized course details and sections visibility handlers
  const showOverview = useCallback(() => setVisibleSection("overview"), []);
  const showInstructor = useCallback(() => setVisibleSection("instructor"), []);

  // Memoized navigation function to go back
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const getCourse = useCallback(async () => {
    try {
      const token = localStorage.getItem("token");

      let { data } = await axios.get(
        `https://back.ig-way.com/api/courses/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourse(data.data);
      console.log(data.data);
      setIsloading(false);
    } catch (err) {
      setErr(err);
    }

    
    
  } , [id]);
  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };


  // Handle rating change
  const handleRateChange = (value) => {
    setRate(value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
    setReviewloading(true);

    const reviewData = {
      review: review,
      rate: rate,
      course_id: course.id
    };

    console.log('Review Data:', reviewData);

    const token = localStorage.getItem("token");

      let { data } = await axios.post(
        `https://back.ig-way.com/api/reviews`,reviewData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }

      );
      setReviewloading(false);
      console.log(data);
      if (data.status === 200) {
        setReviewSubmit(true);
      }
    } catch (error) {
      
    }
    
    };
     // function to handle if price> wallet
     const handlePrice = useCallback(() => {
      if ( 10 < wallet) {
          setMessage("Insufficient balance");
          console.log(message);
      }
      else {
          console.log("price is successfully added");
      }
  });

  // booking
  const handleBooking = useCallback(() => {
    handlePrice();
  });

  return (
    <>
      <ReactHelmet title={t("courseDetails")} />
      {isloading === false ? (
        <div className=" container-fluid">
          <div className="row px-3">
            <div className="col-md-8">
              <div>
                <div className="d-flex align-items-center">
                  <i onClick={goBack} className="fa-solid fa-arrow-left backIcon"></i>
                  <h5 className="ms-5 me-2">{t("courseDetails")}</h5>
                </div>
              </div>
              <h3 className="mt-4">{course.name}</h3>
              <img src={course.image.url} className="mt-3 w-100 course-details-img" alt="" />
              <div className="p-3 mt-2">
                <h5
                  className={`btn   large-font me-2 ${
                    visibleSection === "overview" ? "active border-top border-start border-end" : ""
                  }`}
                  onClick={showOverview}
                >
                  {t("overview")}
                </h5>
                <h5
                  className={`btn  large-font ${
                    visibleSection === "instructor" ? "active border-top border-start border-end" : ""
                  }`}
                  onClick={showInstructor}
                >
                  {t("aboutInstructor")}
                </h5>
              </div>
              {visibleSection === "overview" && (
                <div className="bg-white p-3 rounded-3 shadow mb-5 description border">
                  <div className="">
                    <h5>{t("desc")}</h5>
                    <p>{course.description}</p>
                  </div>
                </div>
              )}
              {visibleSection === "instructor" && (
                <div className="bg-white p-3 rounded-3 shadow mb-5 instructor">
                  <div className="row">
                    <div className="col-md-6 overflow-hidden ">
                        <div className="teacher-img-bgcolor p-4 rounded-circle text-end">
                            <img src={course.teacher.user.avatar.url} alt="" className="w-100 rounded-circle"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-column justify-content-center h-100 overflow-hidden">
                            <h3 className=" header-of-details">MR : {course.teacher.user.fullName}</h3>
                        <p className="h5 opacity-50">{course.teacher.about}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="header-of-details">{course.teacher.studentsCount} Students</h6>
                          {course.teacher.activePrivateLesson ?
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <button
                                type="button"
                                className="btn btn-warning "
                                data-bs-toggle="modal"
                                data-bs-target="#editProfileInfo" 
                              >
                                {t("private")}
                              </button>

                              <div
                                className="modal fade"
                                id="editProfileInfo"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-dialog-centered">
                                  <div className="modal-content ">
                                    <div className="modal-header ">
                                        <div className=" d-flex justify-content-between w-100">
                                          <h1 className="modal-title fs-5 header-of-details w-75 text-end" id="exampleModalLabel">
                                          {t("askPrivate")}
                                          </h1>
                                          <button
                                            type="button"
                                            className="btn-close bg-warning"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                    </div>
                                    <div className="modal-body d-flex justify-content-center align-items-center flex-column">
                                      <img src="/Images/private_lesson_image.png" alt="cards" className='w-50' />
                                      <div className="row w-100 mt-3">
                                        <h5 className="header-of-details">{t("privateCost")} : 40 EG</h5>
                                        <div className="col-md-6">
                                          <label htmlFor="birth_date" className="">
                                          {t("SelectDate")}
                                          </label>
                                          <div className="input-container border">
                                          <input
                                              type="date"
                                              name="birth_date"
                                              className="form-control"
                                          />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <label htmlFor="birth_date" className="">
                                          {t("SelectTimeSlot")}
                                          </label>
                                          <div className="input-container border">
                                          <input
                                              type="date"
                                              name="birth_date"
                                              className="form-control"
                                          />
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                          <p className="fw-bold mb-0 pb-0">{t("notes")}</p>
                                          <textarea name="" id="" className="form-control mt-0 pt-0"></textarea>
                                          <div className="w-100 d-flex justify-content-center align-items-center">
                                            <button className="btn btn-warning mt-3 w-50 header-of-details" onClick={handleBooking}>{t("booking")}</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
                            : ""}
                          
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4 mt-5">
              <div className="bg-white p-3 rounded-3 shadow">
                <div className=" ms-4 mt-3">
                  <h5 className="ms-3">{t("courseDetails")}</h5>
                  <hr className="w-100" />
                  <p className=" opacity-50 mb-0 h5">{t("instructor")}</p>
                  <h6 className="py-0 mx-0 mt-2 header-of-details ">
                    {course.teacher.user.fullName}
                  </h6>
                  <p className=" opacity-50 mb-0 mt-3 h6">
                    {t("p/c")}
                  </p>
                  <div className="d-flex mt-1 align-items-baseline">
                    <p className=" opacity-50 h6 me-2 mt-1">
                      {course.currency}
                    </p>
                    <p className="header-of-details mb-0 h6 ">
                      {course.price}
                    </p>
                  </div>
                  <h6 className="opacity-50 mb-0 mt-2">{t("rate")}</h6>
                  <div className="d-flex align-items-end">
                    <Rating initialValue={Math.round(course.rate)} />
                    <h6 className="ms-2 p-0 mt-1 header-of-details ">
                      {Math.round(course.rate)}
                    </h6>
                  </div>
                  <Link to={`/layout/coursedetails2/${course.id}`}>
                <div className="text-center mt-3">
                  <button className="btn btn-warning  w-50 er">{t("enroll")}</button>
                </div>
                </Link>
                </div>
              </div>
              <div className="bg-white p-3 mb-3 rounded-3 shadow mt-4">
                <div className=" ms-4 mt-3 ">
                  <h5 className="ms-3">{t("courseInfo")}</h5>
                  <hr className="w-100" />
                  <h6>{t("eduSystem")}</h6>
                  <p className=" opacity-50 h6">
                    {course.educationSystem.name}
                  </p>
                  <h6>{t("board")}</h6>
                  <p className=" opacity-50 h6">{course.board.name}</p>
                  <h6>{t("courseLevel")}</h6>
                  <p className=" opacity-50 h6">{course.level.name}</p>
                  <h6>{t("totalEnrollment")}</h6>
                  <p className=" opacity-50 h6">300</p>
                  <div className="d-flex justify-content-center">

                    <button
                                type="button"
                                className="btn btn-warning  mt-3 w-50"
                                data-bs-toggle="modal"
                                data-bs-target="#makeReview"
                              >
                                Make Review
                              </button>
                  </div>


                            <div
                              className="modal fade"
                              id="makeReview"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content ">
                                  <div className="modal-header ">
                                      <div className=" d-flex justify-content-between w-100">
                                        <h1 className="modal-title fs-5 header-of-details ms-4 w-75 text-end" id="exampleModalLabel">
                                        Your journey has come to an end!  
                                        </h1>
                                        <button
                                          type="button"
                                          className="btn-close bg-warning"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                  </div>
                                  <div className="modal-body d-flex justify-content-center align-items-center flex-column">
                                    {reviewSubmit && <p className="alert alert-success w-100 text-center">Review Submitted Successfully</p>}
                                    <img src="/Images/Feedback.png" alt="cards" className='w-50' />
                                    <form onSubmit={handleSubmit}>
                                    <div className="row w-100 mt-3 shadow text-center">
                                      <p className="header-of-details text-center">How would you rank your course's overall satisfaction?</p>
                                      <Rating initialValue={0} onClick={handleRateChange} />
                                      <div className="col-md-12 mt-2 text-start">
                                        <p className="fw-bold mb-0 pb-0">Add Comment</p>
                                        <textarea
                                          name="review"
                                          id="review"
                                          placeholder="Your Text"
                                          className="form-control p-2 mt-0 pt-0"
                                          value={review}
                                          onChange={handleReviewChange}
                                        ></textarea>
                                        <div className="w-100 d-flex justify-content-center align-items-center">
                                        {reviewloading ? <button type="button" className="btn btn-warning w-50 mb-2 mt-3"><i className="fas fa-spinner fa-spin"></i></button>
                                          :<button type="submit" className="btn btn-warning mt-3 w-50 header-of-details mb-2">
                                          Submit
                                        </button>}
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                  </div>
                                  
                                </div>
                              </div>
                            </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}
