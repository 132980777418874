import React from 'react'
import { Link } from "react-router-dom";
import {  Outlet } from "react-router-dom";
import LandingPageNavbar from '../LandingPageNavbar/LandingPageNavbar';
import LandingPageFooter from '../LandingPageFooter/LandingPageFooter';



export default function LandingPageLayout() {
    return <>
        <LandingPageNavbar />
        <Outlet></Outlet>
        <LandingPageFooter/>
        
    </>
}
