import React,{useState, useEffect, useMemo} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { usePost } from '../../usePost';
import { usePrice } from '../../../PriceContext';
import { useWallet } from '../../../WalletContext';
import { useNavigate } from 'react-router-dom';

export default function Vodafone({ recharge, setRecharge,course }) {
    const { t } = useTranslation();
    const [textToCopy, setTextToCopy] = useState('');
    const [copyStatus, setCopyStatus] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { wallet } = useWallet();
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    const userId = user.id || null;
    const { makeRequest, loading, error, data } = usePost("https://back.ig-way.com/api/checkout");
    const [checkout, setCheckout] = useState({});
    const { price, selectedItems, withAssignments, type, newSelectedItemsIds, updateCheckout } = usePrice();
    const [checkoutData, setCheckoutData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
      getVodafone();
    },[]);
    // get vodafone cash 
    const getVodafone = async () => {
      try {
        const token = localStorage.getItem("token");
        let { data } = await axios.get("https://back.ig-way.com/api/settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPhoneNumber([...data.data]);
        setRecharge({ ...recharge, phone_number: data.data[0].value });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };
    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); 
      };

      // Memoize checkout data to prevent unnecessary re-renders
      const memoizedCheckoutData = useMemo(() => {
        if (!userId || price === undefined) return null;
    
        let newCheckoutData = {
          type: type,
          payment_method:3 ,
          user_id: userId,
          with_assignments: withAssignments,
          value: price,
          phone_number: phoneNumber[0]?.value
        };
    
        if (type === 1) { // Course
          newCheckoutData = {
            ...newCheckoutData,
            course_id: selectedItems.course?.id, // Select course ID
          };
        } else if (type === 2) { // Topics
          newCheckoutData = {
            ...newCheckoutData,
            topics: newSelectedItemsIds,
          };
        } else if (type === 3) { // Subcourses
          newCheckoutData = {
            ...newCheckoutData,
            subcourses: newSelectedItemsIds,
          };
        } else if (type === 4) { // Lessons
          newCheckoutData = {
            ...newCheckoutData,
            lessons: newSelectedItemsIds,
          };
        }
    
        return newCheckoutData;
      }, [type, selectedItems, withAssignments, price, userId, newSelectedItemsIds]);
    
      useEffect(() => {
        if (memoizedCheckoutData) {
          setCheckoutData(memoizedCheckoutData);
        }
      }, [memoizedCheckoutData]);
    
    
      const handleCheckout = async (e) => {
        e.preventDefault();
        if (checkoutData) {
          
          await makeRequest(checkoutData);
          navigate(`/layout/coursedetails2/${course.id}`);
        } else {
          console.error("Checkout data is null or undefined.");
        }
      };
    
      const handleShowModal = () => {
        if (price <= wallet) {
          handleCheckout();
        } else {
          setShowModal(true);
        }
      };
      const handleCloseModal = () => {
        setShowModal(false);
      };
      
      const rechargeWallet = () => {
        navigate("/layout/wallet");
      };
  


      if (isLoading) {
        return <div className="d-flex justify-content-center align-items-center spiner">
           <Circles height="80" width="80" color="#FFBC15" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" visible={true}/>
                </div>;
      } 

  return (
    <>
        <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0">
            <label htmlFor="phone-number" className='form-label alltext-black-color h6'>{t("phone")}</label>
            <div className='position-relative'>
              <input type="text" name="phone-number" id="phone-number" className='form-control bg-light py-3 pe-5' value={phoneNumber[0].value}  onChange={(e) => setTextToCopy(e.target.value)} readOnly/>
              <CopyToClipboard text={phoneNumber} onCopy={onCopyText}>
              <i className="fa-regular fa-copy position-absolute top-50 end-0 translate-middle pe-1" style={{color: "#3d3d3d"}} ></i>
              </CopyToClipboard>
              {copyStatus && <p className='text-success position-absolute top-50 end-0 translate-middle pe-1'>{t("copyNumber")}</p>}
            </div>
            <p className='mt-3 text-center fw-bold'>{t("willPay")} {price}</p>
          <button type="submit" className={`w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`} onClick={handleShowModal}>
            Pay
          </button>
          {price > wallet && showModal && (
          <div className="modal fade show d-block igWallet-modal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-4">
                <div className="modal-header justify-content-evenly">
                  <h5 className="modal-title ms-5 me-5" id="exampleModalLongTitle">{t("notCompletedPayment")}</h5>
                  <button type="button" className="close btn btn-main-color text-white fw-bold px-1 py-0 rounded ms-2" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true" className='p-1'>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-column align-items-center">
                    <img src="/Images/igwallet-girl.png" alt="No Money" />
                    <p className="alltext-black-color text-center">{t("notCompletedText")}</p>
                  </div>
                  <div className="modalBtns row">
                    <div className="col-md-6 pe-2">
                      <button className="btn btn-warning w-100 p-2 alltext-side-color fw-bold" onClick={rechargeWallet}>{t("charge")}</button>
                    </div>
                    <div className="col-md-6 ps-0">
                      <button className="btn btn-outline-secondary w-100 p-2 alltext-side-color fw-bold" onClick={handleCloseModal}>{t("anotherPayment")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
            
    </>
  )
}
