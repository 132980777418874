import React from 'react'

export default function NotificationItem({date,items,index}) {
    return <>
        <div key={index} >
            <h6>{date}</h6>
            <ul className='p-0'>
                {items.map((item, index) => {
                    return <div key={index} className='border-bottom'>
                        <div className='d-flex align-items-center p-1 rounded-3 my-1 '>
                        <img src="/Images/def.jpg" className='notification-image rounded-circle mx-1' alt="" />
                        <div className='d-flex justify-content-center mb-0 pb-0 flex-column'>
                                <p className='mb-0 noti-font pb-0'>{ item.body}</p>
                            <p className='opacity-50 noti-font mb-0 pb-0'>{ item.created_at}</p>
                        </div>
                        </div>
                    </div>
                })}
                
                
            </ul>
        </div>
    </>
}
