import logo from './logo.svg';
import './App.css';
import Layout from './Components/Layout/Layout';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import Home from './Components/Home/Home';
import Schedule from './Components/Schedule/Schedule';
import Recordings from './Components/Recordings/Recordings';
import PersonalInfo from './Components/Register/PersonalInfo';
import Transactions from './Components/Transactions/Transactions';
import Courses from './Components/Courses/Courses';
import LoginInfo from './Components/Register/LoginInfo';
import Verify from './Components/Register/Verify';
import Login from './Components/Login/Login';
import ForgetPassword from './Components/Login/ForgetPassword';
import LoginVerify from './Components/Login/LoginVerify';
import ForgetPasswordVerify from './Components/Login/ForgetPasswordVerify';
import NewPassword from './Components/Login/NewPassword';
import GuardianLogin from './Components/GuardianLogin/GuardianLogin';
import GuardianRegister from './Components/GuardianRegister/GuardianRegister';
import ParentVerify from './Components/GuardianRegister/ParentVerify';
import EducationSystem from './Components/Register/EducationSystem';
import OfferdCourse from './Components/Courses/OfferdCourse/OfferdCourse';
import EnrolledCourses from './Components/Courses/EnrolledCourses/EnrolledCourses';
import CompletedCourses from './Components/Courses/CompletedCourses/CompletedCourses';
import CourseDetails from './Components/Courses/CourseDetails/CourseDetails';
import Checkout from './Components/Checkout/Checkout';
import SecondCourseDetails from './Components/Courses/SecondCourseDetails/SecondCourseDetails';
import RecordingDetails from './Components/Recordings/RecordingDetails';
import Assignment from './Components/Courses/Assignment/Assignment';
import Notification from './Components/Notification/Notification';
import Notfound from './Components/NotFound/Notfound';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import LayoutRoute from './Components/LayoutRoute/LayoutRoute';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Profile from './Components/Profile/Profile';
import Wallet from './Components/Wallet/Wallet';
import EditPassword from './Components/EditPassword/EditPassword';
import UserNewPassword from './Components/UserNewPassword/UserNewPassword';
import LessonDetails from './Components/Courses/LessonDetails/LessonDetails';
import WalletRefunds from './Components/Transactions/WalletRefunds/WalletRefunds';
import RechargeTransactions from './Components/Transactions/RechargeTransactions/RechargeTransactions';
import AttendanceTransactions from './Components/Transactions/AttendanceTransactions/AttendanceTransactions';
import LandingPageLayout from './Components/LandingPage/LandingPageLayout/LandingPageLayout';
import LandingPageHome from './Components/LandingPage/LandingPageHome/LandingPageHome';
import LandingPageCourse from './Components/LandingPage/LandingPageCourse/LandingPageCourse';
import LandingPageTeacher from './Components/LandingPage/LandingPageTeacher/LandingPageTeacher';

let routers = createHashRouter([
  {
    path: '/', element: <LandingPageLayout />, children: [
      { index: true, element:<LandingPageHome />},
      { path: 'landingpage-course', element:<LandingPageCourse />},
      { path: 'landingpage-teacher', element:<LandingPageTeacher />},
    
  ]},
  { path: 'login', element: <LayoutRoute><Login /></LayoutRoute> },
  { path: 'logininfo', element:<LayoutRoute> <LoginInfo /></LayoutRoute> },
  { path: 'personalinfo', element: <LayoutRoute><PersonalInfo /></LayoutRoute> },
  { path: 'loginverify', element: <LayoutRoute><LoginVerify /> </LayoutRoute>},
  { path: 'verify', element:<LayoutRoute> <Verify /></LayoutRoute> },
  { path: 'forgetpassword', element: <LayoutRoute><ForgetPassword /> </LayoutRoute>},
  { path: 'newpassword', element: <LayoutRoute><NewPassword /> </LayoutRoute>},
  { path: 'guardianlogin', element: <LayoutRoute><GuardianLogin /></LayoutRoute> },
  { path: 'guardianregister', element: <LayoutRoute><GuardianRegister /></LayoutRoute> },
  { path: 'educatorregister', element: <LayoutRoute><EducationSystem /></LayoutRoute> },
  { path: 'forgetpasswordverify', element: <LayoutRoute><ForgetPasswordVerify/></LayoutRoute> },
  { path: 'parentverify', element: <LayoutRoute><ParentVerify/></LayoutRoute> },
  { path: 'layout', element:<ProtectedRoute> <Layout/> </ProtectedRoute>, children: [
    { path: 'home', element:<ProtectedRoute> <Home /> </ProtectedRoute>},
    { path: 'profile', element:<ProtectedRoute> <Profile /> </ProtectedRoute>},
    { path: 'coursedetails/:id', element:<ProtectedRoute><CourseDetails /></ProtectedRoute>  },
    { path: 'coursedetails2/:id', element: <ProtectedRoute><SecondCourseDetails /></ProtectedRoute> },
    { path: 'lessondetails/:course_id/:lesson_id', element: <ProtectedRoute><LessonDetails /></ProtectedRoute> },
    { path: 'checkout/:id', element: <ProtectedRoute><Checkout /></ProtectedRoute> },
    { path: 'checkout/:id/:lessonId', element: <ProtectedRoute><Checkout /></ProtectedRoute> },
    { path: 'wallet', element: <ProtectedRoute><Wallet /></ProtectedRoute> },
    { path: 'courses', element: <ProtectedRoute><Courses /></ProtectedRoute>, children: [
      { path: 'offeredcourses', element: <ProtectedRoute><OfferdCourse /></ProtectedRoute> },
      { path: 'enrolledcourses', element: <ProtectedRoute><EnrolledCourses /></ProtectedRoute> },
      { path: 'completedcourses', element: <ProtectedRoute><CompletedCourses /></ProtectedRoute> },

    ]},
    { path: 'schedule', element: <ProtectedRoute><Schedule /></ProtectedRoute> },
    { path: 'recordingdetails/:id', element:<ProtectedRoute> <RecordingDetails /></ProtectedRoute> },
    { path: 'notification', element: <ProtectedRoute><Notification /></ProtectedRoute> },
    { path: 'assignments/:id', element: <ProtectedRoute><Assignment /></ProtectedRoute> },
    { path: 'recordings', element:<ProtectedRoute> <Recordings /></ProtectedRoute>},
    { path: 'wallet-refunds', element: <ProtectedRoute><WalletRefunds /></ProtectedRoute> },
    { path: 'recharge-transactions', element: <ProtectedRoute><RechargeTransactions /></ProtectedRoute> },
    { path: 'attendance-transactions', element: <ProtectedRoute><AttendanceTransactions /></ProtectedRoute> },
    { path: 'editpassword', element: <ProtectedRoute><EditPassword /></ProtectedRoute> },
    { path: 'usernewpassword', element: <ProtectedRoute><UserNewPassword/></ProtectedRoute> },
    
  ]
  },
  {path: '*', element: <Notfound/>}
]);
function App() {
  return <I18nextProvider i18n={i18n}>
  <RouterProvider router={routers} />
</I18nextProvider>
}

export default App;
