import React, {useState, useEffect,useMemo, useCallback} from 'react';
import { useParams,Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { Circles } from "react-loader-spinner";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../../ReactHelmet/ReactHelmet';
import Loading from '../../Loading/Loading';

export default function SecondCourseDetails() {
    const { t } = useTranslation();
    const [selectedVideo, setSelectedVideo] = useState([]);
    const [selectedIntroduction, setSelectedIntroduction] = useState([]);
    const [course, setCourse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { id } = useParams();
    const navigate = useNavigate();
    const [visibleSection, setVisibleSection] = useState("overview");
    const [error, setError] = useState("");

    const showOverview = useCallback(() => setVisibleSection("overview"), []);
    const showdiscussion = useCallback(() => setVisibleSection("discussion"), []);
    const goBack = useCallback(() => navigate(-1), [navigate]);

    const getCourse = useCallback(async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.get(
                `https://back.ig-way.com/api/courses/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setCourse(data.data);
            setIsLoading(false);
        } catch (err) {
            setError(err);
        }
    }, [id]);
    
    useEffect(() => {
      getCourse();
  }, []);


    const handleCheckboxChange = (setter, value) => {
        setter((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    const handleSlideDown = useCallback((event) => {
        $(event.currentTarget).parent().find(".drop-list").slideToggle();
        $(event.currentTarget).find(".arrow-icon").toggleClass("up");
    },[]);
 
    if (isLoading || !course) {
        return <Loading />;
    }

  return (
    <>
      <ReactHelmet title={`${course.name} | IG WAY`} />

      <div className="container-fluid">
        <div className="row px-3 py-1">
          <div className="col-md-8">
            <div>
              <div className="d-flex align-items-center">
                <i onClick={goBack} className="fa-solid fa-arrow-left backIcon ms-2"></i>
                <h4 className="ms-5">{t("courseDetails")}</h4>
              </div>
            </div>
            <img src={course.image.url} alt="Video" className='w-100 course-details-img'/>
            <div className="p-3 mt-2">
                <h5 className={`btn large-font me-2 ${visibleSection === "overview" ? "active border-top border-start border-end" : ""}`}onClick={showOverview}>
                  {t("overview")}
                </h5>             
                 <h5 className={`btn  large-font ${visibleSection === "discussion" ? "active border-top border-start border-end" : ""}`} onClick={showdiscussion}>
                  {t("discussion")}
                </h5>
            </div>
            
            {visibleSection === 'overview' && (
              <div className="p-3 rounded-3 shadow mb-5">
                <h4>{t("aboutCourse")}</h4>
                <p className='text-muted'>{course.description}</p>
              </div>
            )}

            {visibleSection === 'discussion' && (
              <div className="p-3 rounded-3 shadow mb-5">
                <h4>{t("questions")}</h4>
                <label htmlFor="note">{t("note")}</label>
                <textarea name="note" id="note" className='form-control' rows="5" ></textarea>
                <div className='d-flex justify-content-end'>
                  <button className='btn btn-warning alltext-black-color my-4 fw-bold px-5 py-3'>{t("send")}</button>
                </div>
              </div>
            )}
          </div>
          
          <div className="col-md-4">
            <div className="p-3">
              <div className="d-flex mb-4">
                <img src="/Images/bars.png" alt="bars" />
                <h4 className='mx-auto'>{t("courseContent")}</h4>
              </div>  
              <form>
                {course.topics.map((topic) => {
                  return <div key={topic.id}>
                    <p className='text-muted mb-0 pb-0'>{topic.name}</p>  
                    {topic.subcourses.map((subcourse) => {
                      return <div className="mb-2" key={subcourse.id}>
                        <label onClick={handleSlideDown} className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center">
                          <p className="p-0 m-0">{subcourse.name}</p>
                          <img src="/Images/arrow-down.png"className="search arrow-icon" alt=""/>
                        </label>
                        <div className="drop-list hidden px-3 border rounded py-2 rounded-2">
                          <div className="form-check  m-0 ">
                            {subcourse.lessons.map((lesson) => {
                              return <div className='d-flex align-items-center justify-content-between' key={lesson.id}>
                                <Link className='d-flex text-decoration-none align-items-center text-dark' to={lesson.is_open===true ?`/layout/lessondetails/${course.id}/${lesson.id}`:`/layout/checkout/${course.id}/${lesson.id}`}>
                                  <div className='me-3 '>
                                    <input  className="form-check-input pointer rounded-circle me-2 bg-warning border-0" type="checkbox" value="Video 1" onChange={() => handleCheckboxChange(setSelectedIntroduction, "Video 1")} checked={lesson.is_viewed}/>
                                    <label className="form-check-label pointer">{ lesson.name}</label>
                                  </div>
                                  {lesson.is_open===true ? <i className="fa-solid fa-eye opacity-50"></i> : <i className="fa-solid fa-lock opacity-50"></i>}
                                  {/* <i className="fa-solid fa-eye"></i> */}
                                </Link>
                                <div className="d-flex align-items-baseline">
                                  <img src="/Images/video.png" alt="video" className='video-icon me-1'/>
                                  <p className="text-muted p-0 m-0 ms-2">{lesson.duration} min</p>
                                </div>
                                
                                </div>
                            })}
                          </div>
                          
                        </div>
                              </div>
                    })}
                </div>
                })}
              </form>
            </div>   
          </div>
        </div>
      </div>      
    </>
  )
}
